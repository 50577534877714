import axios from 'axios'
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'
import { isEmpty, addNewFilter } from '@/utilities';

const state = {
}

const getters = {
    
}

const actions = {
    getSlaManagement(ctx){
        return new Promise((resolve, reject) => {
            axios
                .get(`${jwtDefaultConfig.apiEndpoint}/sla`)
                .then(response => {
                    const { data, status } = response
                    if (status === 200) {
                        return resolve(data?.responseData)
                    }
                    return reject(data)
                })
                .catch(error => reject(error))
        })
    },
    saveSlaManagement(ctx, sla_management){
        const form_data = new FormData();
        form_data.append(`sla_management`, JSON.stringify(sla_management))
        return new Promise((resolve, reject) => {
            axios
                .post(`${jwtDefaultConfig.apiEndpoint}/sla`, form_data)
                .then(response => {
                    const { data, status } = response
                    if (status === 200) {
                        return resolve(data?.responseData)
                    }
                    return reject(data)
                })
                .catch(error => reject(error))
        })
    },
}

const mutations = {
    
}

export const store = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
