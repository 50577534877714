export default [
  {
    path: '/admin/login',
    name: 'apps-admin-login',
    component: () => import('@/views/auth/Login.vue'),
    meta: {
      layout: 'full',
      sharedType: 'admin',
      type: 'admin',
    },
  },
  {
    path: '/admin/cmspages/faq/category',
    name: 'admin-cmspages-faq-category',
    component: () => import('@/views/admin/faq/Category.vue'),
    meta: {
      pageTitle: 'FAQ',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'admin',
    },
  },
  {
    path: '/admin/cmspages/faq',
    name: 'admin-cmspages-faq',
    component: () => import('@/views/admin/faq/Index.vue'),
    meta: {
      pageTitle: 'FAQ',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'admin',
    },
  },
  {
    path: '/admin/cmspages/faq/create',
    name: 'admin-cmspages-faq-create',
    component: () => import('@/views/admin/faq/Manage.vue'),
    meta: {
      pageTitle: 'FAQ Create',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'admin',
    },
  },
  {
    path: '/admin/cmspages/faq/edit/:faqIdEdit',
    name: 'admin-cmspages-faq-edit',
    component: () => import('@/views/admin/faq/Manage.vue'),
    meta: {
      pageTitle: 'FAQ Edit',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'admin',
    },
  },
  {
    path: '/admin/cmspages/faq/view/:faqIdView',
    name: 'admin-cmspages-faq-view',
    component: () => import('@/views/admin/faq/Manage.vue'),
    meta: {
      pageTitle: 'FAQ View',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'admin',
    },
  },
  {
    path: '/admin/cmspages/blog',
    name: 'admin-cmspages-blog',
    component: () => import('@/views/admin/blog/Index.vue'),
    meta: {
      pageTitle: 'Blog',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'admin',
    },
  },
  {
    path: '/admin/cmspages/blog/create',
    name: 'admin-cmspages-blog-create',
    component: () => import('@/views/admin/blog/Manage.vue'),
    meta: {
      pageTitle: 'Blog Create',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'admin',
    },
  },
  {
    path: '/admin/cmspages/blog/edit/:editBlogId',
    name: 'admin-cmspages-blog-edit',
    component: () => import('@/views/admin/blog/Manage.vue'),
    meta: {
      pageTitle: 'Blog Edit',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'admin',
    },
  },
  {
    path: '/admin/cmspages/blog/view/:viewBlogId',
    name: 'admin-cmspages-blog-view',
    component: () => import('@/views/admin/blog/Manage.vue'),
    meta: {
      pageTitle: 'Blog View',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'admin',
    },
  },
  {
    path: '/admin/cmspages/knowledge-base',
    name: 'admin-cmspages-knowledge-base',
    component: () => import('@/views/admin/knowledge-base/Index.vue'),
    meta: {
      pageTitle: 'Knowledge Base',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'admin',
    },
  },
  {
    path: '/admin/cmspages/knowledge-base/category',
    name: 'admin-cmspages-knowledge-base-category',
    component: () => import('@/views/admin/knowledge-base/Category.vue'),
    meta: {
      pageTitle: 'Knowledge Base Category',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'admin',
    },
  },
  {
    path: '/admin/cmspages/knowledge-base/questions',
    name: 'admin-cmspages-knowledge-base-questions',
    component: () => import('@/views/admin/knowledge-base/Questions.vue'),
    meta: {
      pageTitle: 'Knowledge Base Questions',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'admin',
    },
  },
  {
    path: '/admin/cmspages/others',
    name: 'admin-cmspages-other-pages',
    component: () => import('@/views/admin/CMSPages.vue'),
    meta: {
      pageTitle: 'Other Pages',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'admin',
    },
  },
  {
    path: '/admin/utilities/variations',
    name: 'admin-utilities-variations',
    component: () => import('@/views/admin/utilities/Variations.vue'),
    meta: {
      pageTitle: 'Variations',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'admin',
    },
  },
  {
    path: '/admin/settings/translations',
    name: 'admin-language-translations',
    component: () => import('@/views/admin/settings/Translations.vue'),
    meta: {
      pageTitle: 'Translations',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'admin',
    },
  },
  {
    path: '/admin/settings/system-settings',
    name: 'admin-system-settings',
    component: () => import('@/views/admin/SystemSettings.vue'),
    meta: {
      pageTitle: 'System Settings',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'admin',
    },
  },
  {
    path: '/admin/subscription-packages/store',
    name: 'admin-subscription-packages-store',
    component: () => import('@/views/admin/subscription-package/SubscriptionPackages.vue'),
    meta: {
      pageTitle: 'Subscription Packages',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'admin',
    },
  },
  {
    path: '/admin/subscription-packages/provider',
    name: 'admin-subscription-packages-provider',
    component: () => import('@/views/admin/subscription-package/SubscriptionPackages.vue'),
    meta: {
      pageTitle: 'Subscription Packages',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'admin',
    },
  },
  {
    path: '/admin/subscription-packages/user',
    name: 'admin-subscription-packages-user',
    component: () => import('@/views/admin/subscription-package/SubscriptionPackages.vue'),
    meta: {
      pageTitle: 'Subscription Packages',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'admin',
    },
  },
  {
    path: '/admin/subscription-packages/add/:package_for',
    name: 'admin-subscription-packages-add',
    component: () => import('@/views/admin/subscription-package/SubscriptionPackageForm.vue'),
    meta: {
      pageTitle: 'Add Subscription Packages',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'admin',
    },
  },
  {
    path: '/admin/subscription-packages/update/:package_for/:uuid',
    name: 'admin-subscription-packages-update',
    component: () => import('@/views/admin/subscription-package/SubscriptionPackageForm.vue'),
    meta: {
      pageTitle: 'Update Subscription Packages',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'admin',
    },
  },
  {
    path: '/admin/coupons',
    name: 'admin-coupons',
    component: () => import('@/views/admin/subscription-package/CouponCodes.vue'),
    meta: {
      pageTitle: 'Coupon Codes',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'admin',
    },
  },
  {
    path: '/admin/ai/templates',
    name: 'admin-ai-templates',
    component: () => import('@/views/admin/ai/Templates.vue'),
    meta: {
      pageTitle: 'AI Templates',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'admin',
    },
  },
  {
    path: '/admin/subscribers/store',
    name: 'admin-subscribers-store',
    component: () => import('@/views/admin/subscription-package/StoreSubscribers.vue'),
    meta: {
      pageTitle: 'Store Subscribers',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'admin',
    },
  },
  {
    path: '/admin/subscribers/provider',
    name: 'admin-subscribers-provider',
    component: () => import('@/views/admin/subscription-package/ProviderSubscribers.vue'),
    meta: {
      pageTitle: 'Provider Subscribers',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'admin',
    },
  },
  {
    path: '/admin/shops',
    name: 'admin-shops',
    component: () => import('@/views/admin/StoreListing.vue'),
    meta: {
      pageTitle: 'Shops',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'admin',
    },
  },
  {
    path: '/admin/providers',
    name: 'admin-providers',
    component: () => import('@/views/admin/ProviderListing.vue'),
    meta: {
      pageTitle: 'Providers',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'admin',
    },
  },
  {
    path: '/admin/users',
    name: 'admin-users',
    component: () => import('@/views/admin/UserListing.vue'),
    meta: {
      pageTitle: 'User',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'admin',
    },
  },
  {
    path: '/admin/subscribers/user',
    name: 'admin-subscribers-user',
    component: () => import('@/views/admin/subscription-package/UserSubscribers.vue'),
    meta: {
      pageTitle: 'User Subscribers',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'admin',
    },
  },
  {
    path: '/admin/affilation/requests',
    name: 'admin-affilation-requests',
    component: () => import('@/views/admin/affilation/Requests.vue'),
    meta: {
      pageTitle: 'Affilate Requests',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'admin',
    },
  },
  {
    path: '/admin/import/users',
    name: 'admin-import-users',
    component: () => import('@/views/admin/import/StoreProvider.vue'),
    meta: {
      pageTitle: 'Import Stores/Providers',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'admin',
    },
  },
]
