const appUrl = window.location.origin;
const curUrl = window.location.href;
const allPages = [];


const commonOgImage = [
  { property: 'og:image', content: "https://momitnow.com/img/MOM%20AI.d3e9c4a0.webp" },
  { property: 'og:image:width', content: "1200" },
  { property: 'og:image:height', content: "630" },
];

// MOM Main Page
allPages.push(mappedMetaData({
  title: 'MOM | AI | Order Management | DMS | Booking Management ',
  description: 'Welcome to MOM - Ultimate AI powered Marketplace, an AI-powered platform that enables you to create AI content, digital storefronts, booking management, and expand your business.',
  canonical: `${appUrl}/en/main`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}` },
    { property: 'og:title', content: 'MOM | AI | Order Management | DMS | Booking Management' },
    { property: 'og:description', content: 'Welcome to MOM - Ultimate AI powered Marketplace, an AI-powered platform that enables you to create AI content, digital storefronts, booking management, and expand your business.' },
  ]
}));

// Main Page
allPages.push(mappedMetaData({
  title: 'MOM | AI | Order Management | DMS | Booking Management ',
  description: 'Welcome to MOM - Ultimate AI powered Marketplace, an AI-powered platform that enables you to create AI content, digital storefronts, booking management, and expand your business.',
  canonical: `${appUrl}/en/main`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/en/main` },
    { property: 'og:title', content: 'MOM | AI | Order Management | DMS | Booking Management' },
    { property: 'og:description', content: 'Welcome to MOM - Ultimate AI powered Marketplace, an AI-powered platform that enables you to create AI content, digital storefronts, booking management, and expand your business.' },
  ]
}));


allPages.push(mappedMetaData({
  title: 'Introduction to Turning Your Hobby into an Online Business - MOM ',
  description: 'Discover how to turn your hobby into a thriving online business with MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/blog/introduction-to-turning-your-hobby-into-an-online-business/42`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/blog/introduction-to-turning-your-hobby-into-an-online-business/42` },
    { property: 'og:title', content: 'Introduction to Turning Your Hobby into an Online Business - MOM ' },
    { property: 'og:description', content: 'Discover how to turn your hobby into a thriving online business with MOM - Ultimate AI powered Marketplace.' },
  ]
}));

allPages.push(mappedMetaData({
  title: 'Understanding the Virtual Marketplace: An Overview - MOM ',
  description: 'Get an insightful overview of the virtual marketplace and its dynamics on MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/blog/understanding-the-virtual-marketplace-an-overview/43`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/blog/understanding-the-virtual-marketplace-an-overview/43` },
    { property: 'og:title', content: 'Understanding the Virtual Marketplace: An Overview - MOM ' },
    { property: 'og:description', content: 'Get an insightful overview of the virtual marketplace and its dynamics on MOM - Ultimate AI powered Marketplace.' },
  ]
}));

allPages.push(mappedMetaData({
  title: 'Are AI Detectors Accurate? A Comprehensive Review - MOM ',
  description: 'Explore the accuracy of AI detectors in this comprehensive review on MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/blog/are-ai-detectors-accurate-a-comprehensive-review/44`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/blog/are-ai-detectors-accurate-a-comprehensive-review/44` },
    { property: 'og:title', content: 'Are AI Detectors Accurate? A Comprehensive Review - MOM ' },
    { property: 'og:description', content: 'Explore the accuracy of AI detectors in this comprehensive review on MOM - Ultimate AI powered Marketplace.' },
  ]
}));

allPages.push(mappedMetaData({
  title: 'Are AI Generated Images Copyrighted? An In-Depth Guide on AI and Copyright Law - MOM ',
  description: 'Learn about the copyright implications of AI-generated images in this in-depth guide on MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/blog/are-ai-generated-images-copyrighted-an-in-depth-guide-on-ai-and-copyright-law/45`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/blog/are-ai-generated-images-copyrighted-an-in-depth-guide-on-ai-and-copyright-law/45` },
    { property: 'og:title', content: 'Are AI Generated Images Copyrighted? An In-Depth Guide on AI and Copyright Law - MOM ' },
    { property: 'og:description', content: 'Learn about the copyright implications of AI-generated images in this in-depth guide on MOM - Ultimate AI powered Marketplace.' },
  ]
}));

allPages.push(mappedMetaData({
  title: 'Fact or Fiction: Debunking the Myth of AI Robots - MOM ',
  description: 'Separate fact from fiction as we debunk the myth of AI robots on MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/blog/fact-or-fiction-debunking-the-myth-of-ai-robots/46`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/blog/fact-or-fiction-debunking-the-myth-of-ai-robots/46` },
    { property: 'og:title', content: 'Fact or Fiction: Debunking the Myth of AI Robots - MOM ' },
    { property: 'og:description', content: 'Separate fact from fiction as we debunk the myth of AI robots on MOM - Ultimate AI powered Marketplace.' },
  ]
}));

allPages.push(mappedMetaData({
  title: 'Are AI Stocks a Good Investment? An Analysis of the AI Industry - MOM ',
  description: 'Gain insights into the potential of AI stocks as investments with this analysis on MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/blog/are-ai-stocks-a-good-investment-an-analysis-of-the-ai-industry/47`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/blog/are-ai-stocks-a-good-investment-an-analysis-of-the-ai-industry/47` },
    { property: 'og:title', content: 'Are AI Stocks a Good Investment? An Analysis of the AI Industry - MOM ' },
    { property: 'og:description', content: 'Gain insights into the potential of AI stocks as investments with this analysis on MOM - Ultimate AI powered Marketplace.' },
  ]
}));

allPages.push(mappedMetaData({
  title: 'Is Your AI App Safe? A Look into Security in AI Applications - MOM ',
  description: 'Explore the security aspects of AI applications and ensure the safety of your AI app on MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/blog/is-your-ai-app-safe-a-look-into-security-in-ai-applications/48`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/blog/is-your-ai-app-safe-a-look-into-security-in-ai-applications/48` },
    { property: 'og:title', content: 'Is Your AI App Safe? A Look into Security in AI Applications - MOM ' },
    { property: 'og:description', content: 'Explore the security aspects of AI applications and ensure the safety of your AI app on MOM - Ultimate AI powered Marketplace.' },
  ]
}));

allPages.push(mappedMetaData({
  title: 'Do AI Content Detectors Hit the Mark? Measuring Accuracy and Reliability - MOM ',
  description: 'Assess the accuracy and reliability of AI content detectors on MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/blog/do-ai-content-detectors-hit-the-mark-measuring-accuracy-and-reliability/49`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/blog/do-ai-content-detectors-hit-the-mark-measuring-accuracy-and-reliability/49` },
    { property: 'og:title', content: 'Do AI Content Detectors Hit the Mark? Measuring Accuracy and Reliability - MOM ' },
    { property: 'og:description', content: 'Assess the accuracy and reliability of AI content detectors on MOM - Ultimate AI powered Marketplace.' },
  ]
}));

allPages.push(mappedMetaData({
  title: 'Does Reddit Trust AI Checkers? Analyzing Discussions and Opinions on AI Accuracy - MOM ',
  description: 'Analyze discussions and opinions on AI accuracy and Reddit\'s trust in AI checkers on MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/blog/does-reddit-trust-ai-checkers-analyzing-discussions-and-opinions-on-ai-accuracy/50`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/blog/does-reddit-trust-ai-checkers-analyzing-discussions-and-opinions-on-ai-accuracy/50` },
    { property: 'og:title', content: 'Does Reddit Trust AI Checkers? Analyzing Discussions and Opinions on AI Accuracy - MOM ' },
    { property: 'og:description', content: 'Analyze discussions and opinions on AI accuracy and Reddit\'s trust in AI checkers on MOM - Ultimate AI powered Marketplace.' },
  ]
}));

allPages.push(mappedMetaData({
  title: 'The Future is Here: Can AI Write Code, Build Websites, and Edit Videos? - MOM ',
  description: 'Explore the capabilities of AI in writing code, building websites, and editing videos on MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/blog/the-future-is-here-can-ai-write-code-build-websites-and-edit-videos/51`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/blog/the-future-is-here-can-ai-write-code-build-websites-and-edit-videos/51` },
    { property: 'og:title', content: 'The Future is Here: Can AI Write Code, Build Websites, and Edit Videos? - MOM ' },
    { property: 'og:description', content: 'Explore the capabilities of AI in writing code, building websites, and editing videos on MOM - Ultimate AI powered Marketplace.' },
  ]
}));

allPages.push(mappedMetaData({
  title: 'Can AI Cure Cancer, Predict Stocks, or Write Books? Exploring the Limits of AI - MOM ',
  description: 'Delve into the limits of AI technology in curing cancer, predicting stocks, and writing books on MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/blog/can-ai-cure-cancer-predict-stocks-or-write-books-exploring-the-limits-of-ai/53`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/blog/can-ai-cure-cancer-predict-stocks-or-write-books-exploring-the-limits-of-ai/53` },
    { property: 'og:title', content: 'Can AI Cure Cancer, Predict Stocks, or Write Books? Exploring the Limits of AI - MOM ' },
    { property: 'og:description', content: 'Delve into the limits of AI technology in curing cancer, predicting stocks, and writing books on MOM - Ultimate AI powered Marketplace.' },
  ]
}));

allPages.push(mappedMetaData({
  title: 'The Evolving World of AI Trading Bots: Are They Legal and Do They Work? - MOM ',
  description: 'Discover the legality and effectiveness of AI trading bots in the evolving world of finance on MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/blog/the-evolving-world-of-ai-trading-bots-are-they-legal-and-do-they-work/52`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/blog/the-evolving-world-of-ai-trading-bots-are-they-legal-and-do-they-work/52` },
    { property: 'og:title', content: 'The Evolving World of AI Trading Bots: Are They Legal and Do They Work? - MOM ' },
    { property: 'og:description', content: 'Discover the legality and effectiveness of AI trading bots in the evolving world of finance on MOM - Ultimate AI powered Marketplace.' },
  ]
}));

allPages.push(mappedMetaData({
  title: 'Reasons Why It\'s Important to Work with a Payments Partner - MOM ',
  description: 'Explore the benefits and importance of working with a payments partner in your online business on MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/blog/-reasons-why-its-important-to-work-with-a-payments-partner/8`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/blog/-reasons-why-its-important-to-work-with-a-payments-partner/8` },
    { property: 'og:title', content: 'Reasons Why It\'s Important to Work with a Payments Partner - MOM ' },
    { property: 'og:description', content: 'Explore the benefits and importance of working with a payments partner in your online business on MOM - Ultimate AI powered Marketplace.' },
  ]
}));

allPages.push(mappedMetaData({
  title: 'How to Start a Restaurant - MOM ',
  description: 'Discover the essentials of starting your own restaurant and learn how MOM - Ultimate AI powered Marketplace can help streamline the process.',
  canonical: `${appUrl}/blog/how-to-start-a-restaurant/9`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/blog/how-to-start-a-restaurant/9` },
    { property: 'og:title', content: 'How to Start a Restaurant - MOM ' },
    { property: 'og:description', content: 'Discover the essentials of starting your own restaurant and learn how MOM - Ultimate AI powered Marketplace can help streamline the process.' },
  ]
}));

allPages.push(mappedMetaData({
  title: 'How to Start an Online Fashion Store - MOM ',
  description: 'Step into the world of fashion e-commerce and learn how to start your own online fashion store with MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/blog/how-to-start-an-online-fashion-store/11`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/blog/how-to-start-an-online-fashion-store/11` },
    { property: 'og:title', content: 'How to Start an Online Fashion Store - MOM ' },
    { property: 'og:description', content: 'Step into the world of fashion e-commerce and learn how to start your own online fashion store with MOM - Ultimate AI powered Marketplace.' },
  ]
}));

allPages.push(mappedMetaData({
  title: 'How to Sell Liquor Online - MOM ',
  description: 'Uncover the legalities and best practices for selling liquor online with guidance from MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/blog/how-to-sell-liquor-online/12`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/blog/how-to-sell-liquor-online/12` },
    { property: 'og:title', content: 'How to Sell Liquor Online - MOM ' },
    { property: 'og:description', content: 'Uncover the legalities and best practices for selling liquor online with guidance from MOM - Ultimate AI powered Marketplace.' },
  ]
}));

allPages.push(mappedMetaData({
  title: 'Why Practice Yoga - MOM ',
  description: 'Explore the countless benefits of practicing yoga and how it can enhance your lifestyle, with insights from MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/blog/why-practice-yoga/14`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/blog/why-practice-yoga/14` },
    { property: 'og:title', content: 'Why Practice Yoga - MOM ' },
    { property: 'og:description', content: 'Explore the countless benefits of practicing yoga and how it can enhance your lifestyle, with insights from MOM - Ultimate AI powered Marketplace.' },
  ]
}));

allPages.push(mappedMetaData({
  title: 'Setting Up Your Digital Store - MOM ',
  description: 'Get step-by-step instructions on how to set up your digital store quickly and efficiently on MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/blog/setting-up-your-digital-store/15`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/blog/setting-up-your-digital-store/15` },
    { property: 'og:title', content: 'Setting Up Your Digital Store - MOM ' },
    { property: 'og:description', content: 'Get step-by-step instructions on how to set up your digital store quickly and efficiently on MOM - Ultimate AI powered Marketplace.' },
  ]
}));

allPages.push(mappedMetaData({
  title: 'Harnessing the Power of Online Marketplaces - A Guide to Becoming a Provider on MOM ',
  description: 'Maximize your sales potential by harnessing the power of online marketplaces with MOM - Ultimate AI powered Marketplace. Become a top provider with our guide.',
  canonical: `${appUrl}/blog/harnessing-the-power-of-online-marketplaces-a-guide-to-becoming-a-provider-on-mom-app/16`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/blog/harnessing-the-power-of-online-marketplaces-a-guide-to-becoming-a-provider-on-mom-app/16` },
    { property: 'og:title', content: 'Harnessing the Power of Online Marketplaces - A Guide to Becoming a Provider on MOM ' },
    { property: 'og:description', content: 'Maximize your sales potential by harnessing the power of online marketplaces with MOM - Ultimate AI powered Marketplace. Become a top provider with our guide.' },
  ]
}));

allPages.push(mappedMetaData({
  title: 'How to Sell Antiques Online The Right Way With MOM ',
  description: 'Discover the right way to sell antiques online and connect with collectors worldwide through MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/blog/how-to-sell-antiques-online-the-right-way-with-mom/17`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/blog/how-to-sell-antiques-online-the-right-way-with-mom/17` },
    { property: 'og:title', content: 'How to Sell Antiques Online The Right Way With MOM ' },
    { property: 'og:description', content: 'Discover the right way to sell antiques online and connect with collectors worldwide through MOM - Ultimate AI powered Marketplace.' },
  ]
}));

allPages.push(mappedMetaData({
  title: 'The Sweet Success: How to Sell Cookies Online Successfully with MOM ',
  description: 'Learn the recipe for success in selling cookies online with MOM. Sweeten your business with tips from this comprehensive guide.',
  canonical: `${appUrl}/blog/the-sweet-success-how-to-sell-cookies-online-successfully-with-mom/18`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/blog/the-sweet-success-how-to-sell-cookies-online-successfully-with-mom/18` },
    { property: 'og:title', content: 'The Sweet Success: How to Sell Cookies Online Successfully with MOM ' },
    { property: 'og:description', content: 'Learn the recipe for success in selling cookies online with MOM. Sweeten your business with tips from this comprehensive guide.' },
  ]
}));

allPages.push(mappedMetaData({
  title: 'How to Start a Candle Making Business: A Comprehensive Guide - MOM ',
  description: 'Illuminate the path to starting a candle making business with this comprehensive guide from MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/blog/how-to-start-a-candle-making-business-a-comprehensive-guide/19`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/blog/how-to-start-a-candle-making-business-a-comprehensive-guide/19` },
    { property: 'og:title', content: 'How to Start a Candle Making Business: A Comprehensive Guide - MOM ' },
    { property: 'og:description', content: 'Illuminate the path to starting a candle making business with this comprehensive guide from MOM - Ultimate AI powered Marketplace.' },
  ]
}));

allPages.push(mappedMetaData({
  title: 'A Comprehensive Guide: How to Sell Furniture Online - MOM ',
  description: 'Transform your furniture business by taking it online. This comprehensive guide by MOM - Ultimate AI powered Marketplace tells you how.',
  canonical: `${appUrl}/blog/a-comprehensive-guide-how-to-sell-furniture-online/20`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/blog/a-comprehensive-guide-how-to-sell-furniture-online/20` },
    { property: 'og:title', content: 'A Comprehensive Guide: How to Sell Furniture Online - MOM ' },
    { property: 'og:description', content: 'Transform your furniture business by taking it online. This comprehensive guide by MOM - Ultimate AI powered Marketplace tells you how.' },
  ]
}));

allPages.push(mappedMetaData({
  title: 'How to Sell Courses Online: A Comprehensive Guide - MOM ',
  description: 'Share your knowledge with the world. This comprehensive guide by MOM - Ultimate AI powered Marketplace shows you how to sell courses online effectively.',
  canonical: `${appUrl}/blog/how-to-sell-courses-online-a-comprehensive-guide/21`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/blog/how-to-sell-courses-online-a-comprehensive-guide/21` },
    { property: 'og:title', content: 'How to Sell Courses Online: A Comprehensive Guide - MOM ' },
    { property: 'og:description', content: 'Share your knowledge with the world. This comprehensive guide by MOM - Ultimate AI powered Marketplace shows you how to sell courses online effectively.' },
  ]
}));

allPages.push(mappedMetaData({
  title: 'Simple Steps to Start a Successful Laundry Business - MOM ',
  description: 'Get your laundry business plan ready. These simple steps by MOM - Ultimate AI powered Marketplace will help kick-start your successful venture.',
  canonical: `${appUrl}/blog/simple-steps-to-start-a-successful-laundry-business/22`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/blog/simple-steps-to-start-a-successful-laundry-business/22` },
    { property: 'og:title', content: 'Simple Steps to Start a Successful Laundry Business - MOM ' },
    { property: 'og:description', content: 'Get your laundry business plan ready. These simple steps by MOM - Ultimate AI powered Marketplace will help kick-start your successful venture.' },
  ]
}));

allPages.push(mappedMetaData({
  title: 'MOM : The Ultimate E-commerce Website Builder for Small Businesses',
  description: 'Discover why MOM - Ultimate AI powered Marketplace is the ultimate e-commerce website builder for small businesses. Build your online store today!',
  canonical: `${appUrl}/blog/mom-app-the-ultimate-e-commerce-website-builder-for-small-businesses/23`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/blog/mom-app-the-ultimate-e-commerce-website-builder-for-small-businesses/23` },
    { property: 'og:title', content: 'MOM : The Ultimate E-commerce Website Builder for Small Businesses' },
    { property: 'og:description', content: 'Discover why MOM - Ultimate AI powered Marketplace is the ultimate e-commerce website builder for small businesses. Build your online store today!' },
  ]
}));

allPages.push(mappedMetaData({
  title: 'Embrace the Future: E-commerce Website Builder for Small Businesses - MOM ',
  description: 'Future-proof your small business with MOM - Ultimate AI powered Marketplace\'s e-commerce website builder.',
  canonical: `${appUrl}/blog/embrace-the-future-e-commerce-website-builder-for-small-businesses-in-/24`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/blog/embrace-the-future-e-commerce-website-builder-for-small-businesses-in-/24` },
    { property: 'og:title', content: 'Embrace the Future: E-commerce Website Builder for Small Businesses - MOM ' },
    { property: 'og:description', content: 'Future-proof your small business with MOM - Ultimate AI powered Marketplace\'s e-commerce website builder.' },
  ]
}));

allPages.push(mappedMetaData({
  title: 'How to Sell Jewelry Online: A Comprehensive Guide for Online Marketplace - MOM ',
  description: 'Conquer the online jewelry market with this comprehensive guide from MOM - Ultimate AI powered Marketplace, designed for the trends.', // Replace [Year] with the appropriate ye,
  canonical: `${appUrl}/blog/how-to-sell-jewelry-online-a-comprehensive-guide-for-the--online-marketplace/25`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/blog/how-to-sell-jewelry-online-a-comprehensive-guide-for-the--online-marketplace/25` }, // Replace "--" with the appropriate year
    { property: 'og:title', content: 'How to Sell Jewelry Online: A Comprehensive Guide for the Online Marketplace - MOM ' },
    { property: 'og:description', content: 'Conquer the online jewelry market with this comprehensive guide from MOM - Ultimate AI powered Marketplace, designed for the trends.' }, // Replace [Year] with the appropriate year
  ]
}));

allPages.push(mappedMetaData({
  title: 'How to Sell Crafts Online: Quick Start Guide - MOM ',
  description: 'Turn your creativity into profit. This quick start guide by MOM - Ultimate AI powered Marketplace shows you how to sell crafts online with ease.',
  canonical: `${appUrl}/blog/how-to-sell-crafts-online--quick-start-guide/26`, // Make sure the blog post number and title match the actual U,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/blog/how-to-sell-crafts-online--quick-start-guide/26` }, // Make sure the blog post number and title match the actual URL
    { property: 'og:title', content: 'How to Sell Crafts Online: Quick Start Guide - MOM ' },
    { property: 'og:description', content: 'Turn your creativity into profit. This quick start guide by MOM - Ultimate AI powered Marketplace shows you how to sell crafts online with ease.' },
  ]
}));

allPages.push(mappedMetaData({
  title: 'How to Sell Wine Online: Step-by-Step Guide - MOM ',
  description: 'Uncork the secret to selling wine online with this step-by-step guide from MOM - Ultimate AI powered Marketplace. Start pouring profits today!',
  canonical: `${appUrl}/blog/how-to-sell-wine-online--step-by-step-guide/27`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/blog/how-to-sell-wine-online--step-by-step-guide/27` },
    { property: 'og:title', content: 'How to Sell Wine Online: Step-by-Step Guide - MOM ' },
    { property: 'og:description', content: 'Uncork the secret to selling wine online with this step-by-step guide from MOM - Ultimate AI powered Marketplace. Start pouring profits today!' },
  ]
}));

allPages.push(mappedMetaData({
  title: 'How to Sell Purses Online in [Number] Simple Steps - MOM ',
  description: 'Selling purses online made easy! Follow our guide to have your online purse store up and running with MOM.', // Replace [Number] with actual number of step,
  canonical: `${appUrl}/blog/how-to-sell-purses-online-in--simple-steps/29`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/blog/how-to-sell-purses-online-in--simple-steps/29` },
    { property: 'og:title', content: 'How to Sell Purses Online in [Number] Simple Steps - MOM ' },
    { property: 'og:description', content: 'Selling purses online made easy! Follow our guide to have your online purse store up and running with MOM.' },
  ]
}));

allPages.push(mappedMetaData({
  title: 'How to Sell Merchandise Online: Step-by-Step Guide - MOM ',
  description: 'Master the art of online merchandise sales with our step-by-step guide. Get started with MOM - Ultimate AI powered Marketplace today!', // Replace [Year] with the current yea,
  canonical: `${appUrl}/blog/how-to-sell-merchandise-online--step-by-step-guide-for-/30`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/blog/how-to-sell-merchandise-online--step-by-step-guide-for-/30` },
    { property: 'og:title', content: 'How to Sell Merchandise Online: Step-by-Step Guide - MOM ' },
    { property: 'og:description', content: 'Master the art of online merchandise sales with our step-by-step guide. Get started with MOM - Ultimate AI powered Marketplace today!' }, // Replace [Year] with the current year.
  ]
}));

allPages.push(mappedMetaData({
  title: 'How to Sell Food Online: Guide for Beginners - MOM ',
  description: 'Discover a practical approach to selling food online with this beginner-friendly guide by MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/blog/how-to-sell-food-online--guide-for-beginners/31`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/blog/how-to-sell-food-online--guide-for-beginners/31` },
    { property: 'og:title', content: 'How to Sell Food Online: Guide for Beginners - MOM ' },
    { property: 'og:description', content: 'Discover a practical approach to selling food online with this beginner-friendly guide by MOM - Ultimate AI powered Marketplace.' },
  ]
}));

allPages.push(mappedMetaData({
  title: 'How to Sell Makeup Online: Steps to Make It Easy for You - MOM ',
  description: 'Enter the beauty industry by selling makeup online. MOM - Ultimate AI powered Marketplace explains simple steps to start easily.', // Replace [Number] with the actual numbe,
  canonical: `${appUrl}/blog/how-to-sell-makeup-online---steps-to-make-it-easy-for-you/32`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/blog/how-to-sell-makeup-online---steps-to-make-it-easy-for-you/32` },
    { property: 'og:title', content: 'How to Sell Makeup Online: Steps to Make It Easy for You - MOM ' },
    { property: 'og:description', content: 'Enter the beauty industry by selling makeup online. MOM - Ultimate AI powered Marketplace explains simple steps to start easily.' }, // Replace [Number] with the actual number.
    { rel: 'canonical', href: `${appUrl}/blog/how-to-sell-makeup-online---steps-to-make-it-easy-for-you/32` }
  ]
}));

allPages.push(mappedMetaData({
  title: 'The Ultimate Guide on How to Sell Electronics Online - MOM ',
  description: 'Navigate the lucrative world of selling electronics online with this ultimate guide from MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/blog/the-ultimate-guide-on-how-to-sell-electronics-online/33`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/blog/the-ultimate-guide-on-how-to-sell-electronics-online/33` },
    { property: 'og:title', content: 'The Ultimate Guide on How to Sell Electronics Online - MOM ' },
    { property: 'og:description', content: 'Navigate the lucrative world of selling electronics online with this ultimate guide from MOM - Ultimate AI powered Marketplace.' },
  ]
}));

allPages.push(mappedMetaData({
  title: 'How to Sell Plants Online - MOM ',
  description: 'Green thumb? Turn your plant passion into profit by selling plants online with these tips from MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/blog/how-to-sell-plants-online/34`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/blog/how-to-sell-plants-online/34` },
    { property: 'og:title', content: 'How to Sell Plants Online - MOM ' },
    { property: 'og:description', content: 'Green thumb? Turn your plant passion into profit by selling plants online with these tips from MOM - Ultimate AI powered Marketplace.' },
  ]
}));

allPages.push(mappedMetaData({
  title: 'How to Sell Shoes Online - MOM ',
  description: 'Step up your online business game by selling shoes online. This guide by MOM - Ultimate AI powered Marketplace shows you how to do it in [Year].', // Replace [Year] with the current yea,
  canonical: `${appUrl}/blog/how-to-sell-shoes-online-in-/35`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/blog/how-to-sell-shoes-online-in-/35` },
    { property: 'og:title', content: 'How to Sell Shoes Online - MOM ' },
    { property: 'og:description', content: 'Step up your online business game by selling shoes online. This guide by MOM - Ultimate AI powered Marketplace shows you how to do it in [Year].' }, // Replace [Year] with the current year.
  ]
}));

allPages.push(mappedMetaData({
  title: 'How to Sell Coffee Online: Step-by-Step Guide - MOM - Ultimate AI powered Marketplace',
  description: 'Jumpstart your journey of selling coffee online with this step guide from MOM - Ultimate AI powered Marketplace.', // Replace [Number] with either the number of steps or the current year as appropriat,
  canonical: `${appUrl}/blog/how-to-sell-coffee-online-in---step-by-step-guide/36`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/blog/how-to-sell-coffee-online-in---step-by-step-guide/36` },
    { property: 'og:title', content: 'How to Sell Coffee Online: Step-by-Step Guide - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Jumpstart your journey of selling coffee online with this step guide from MOM - Ultimate AI powered Marketplace.' }, // Replace [Number] with either the number of steps or the current year as appropriate.
  ]
}));

allPages.push(mappedMetaData({
  title: 'How to Start an Online Store: No Code Required - MOM ',
  description: 'Launching an online store has never been easier. Follow this guide by MOM - Ultimate AI powered Marketplace to create your no-code e-commerce site in [Year].', // Replace [Year] with the actual yea,
  canonical: `${appUrl}/blog/how-to-start-an-online-store-in---no-code-required/37`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/blog/how-to-start-an-online-store-in---no-code-required/37` },
    { property: 'og:title', content: 'How to Start an Online Store: No Code Required - MOM ' },
    { property: 'og:description', content: 'Launching an online store has never been easier. Follow this guide by MOM - Ultimate AI powered Marketplace to create your no-code e-commerce site in [Year].' }, // Replace [Year] with the actual year.
  ]
}));

allPages.push(mappedMetaData({
  title: 'How to Sell Books Online: A Detailed Guide - MOM ',
  description: 'Reach a global audience of readers by selling books online. This guide by MOM - Ultimate AI powered Marketplace covers all details for [Year].', // Replace [Year] with the current yea,
  canonical: `${appUrl}/blog/how-to-sell-books-online--a-detailed-guide-for-/39`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/blog/how-to-sell-books-online--a-detailed-guide-for-/39` },
    { property: 'og:title', content: 'How to Sell Books Online: A Detailed Guide - MOM ' },
    { property: 'og:description', content: 'Reach a global audience of readers by selling books online. This guide by MOM - Ultimate AI powered Marketplace covers all details for [Year].' }, // Replace [Year] with the current year.
  ]
}));

allPages.push(mappedMetaData({
  title: 'Top Women\'s Accessories to Sell Online - MOM ',
  description: 'Discover the top women\'s accessories that are trending in the online market. This MOM - Ultimate AI powered Marketplace guide helps you select the best items to sell.',
  canonical: `${appUrl}/blog/top-womens-accessories-to-sell-online/40`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/blog/top-womens-accessories-to-sell-online/40` },
    { property: 'og:title', content: 'Top Women\'s Accessories to Sell Online - MOM ' },
    { property: 'og:description', content: 'Discover the top women\'s accessories that are trending in the online market. This MOM - Ultimate AI powered Marketplace guide helps you select the best items to sell.' },
  ]
}));

allPages.push(mappedMetaData({
  title: 'A Comprehensive Guide to Men\'s Accessories for Online Selling - MOM ',
  description: 'Learn about the most in-demand men\'s accessories for online selling with this comprehensive guide from MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/blog/a-comprehensive-guide-to-mens-accessories-for-online-selling/41`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/blog/a-comprehensive-guide-to-mens-accessories-for-online-selling/41` },
    { property: 'og:title', content: 'A Comprehensive Guide to Men\'s Accessories for Online Selling - MOM ' },
    { property: 'og:description', content: 'Learn about the most in-demand men\'s accessories for online selling with this comprehensive guide from MOM - Ultimate AI powered Marketplace.' },
  ]
}));

// Privacy Policy Page
allPages.push(mappedMetaData({
  title: 'Privacy Policy - MOM - Ultimate AI powered Marketplace',
  description: 'Read the MOM - Ultimate AI powered Marketplace privacy policy to understand how we respect and protect your personal information.',
  canonical: `${appUrl}/privacy-policy`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/privacy-policy` },
    { property: 'og:title', content: 'Privacy Policy - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Read the MOM - Ultimate AI powered Marketplace privacy policy to understand how we respect and protect your personal information.' },
  ]
}));

// User Order Page
allPages.push(mappedMetaData({
  title: 'View Your Orders - MOM - Ultimate AI powered Marketplace',
  description: 'Easily track and manage your orders on the MOM - Ultimate AI powered Marketplace user portal.',
  canonical: `${appUrl}/user/order`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/user/order` },
    { property: 'og:title', content: 'View Your Orders - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Easily track and manage your orders on the MOM - Ultimate AI powered Marketplace user portal.' },
  ]
}));

// User Services Page
allPages.push(mappedMetaData({
  title: 'Your Services - MOM - Ultimate AI powered Marketplace',
  description: 'Access all the services you are using and manage your subscriptions on the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/user/services`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/user/services` },
    { property: 'og:title', content: 'Your Services - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Access all the services you are using and manage your subscriptions on the MOM - Ultimate AI powered Marketplace.' },
  ]
}));

// User Service Request Page
allPages.push(mappedMetaData({
  title: 'Request a Service - MOM - Ultimate AI powered Marketplace',
  description: 'Need assistance or a new service? Place requests quickly through the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/user/service/request/`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/user/service/request/` },
    { property: 'og:title', content: 'Request a Service - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Need assistance or a new service? Place requests quickly through the MOM - Ultimate AI powered Marketplace.' },
  ]
}));

// User Store Order Page
allPages.push(mappedMetaData({
  title: 'Store Order Management - MOM - Ultimate AI powered Marketplace',
  description: 'Review your store orders and manage them effectively with the MOM - Ultimate AI powered Marketplace dashboard.',
  canonical: `${appUrl}/user/store/order/`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/user/store/order/` },
    { property: 'og:title', content: 'Store Order Management - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Review your store orders and manage them effectively with the MOM - Ultimate AI powered Marketplace dashboard.' },
  ]
}));

// Provider Service Agent View Page
allPages.push(mappedMetaData({
  title: 'Service Agent Management - MOM - Ultimate AI powered Marketplace',
  description: 'Providers can view and manage service requests assigned to them on the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/provider/service/agent/view/`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/provider/service/agent/view/` },
    { property: 'og:title', content: 'Service Agent Management - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Providers can view and manage service requests assigned to them on the MOM - Ultimate AI powered Marketplace.' },
  ]
}));

// Shop Dashboard Page
allPages.push(mappedMetaData({
  title: 'Shop Dashboard - MOM - Ultimate AI powered Marketplace',
  description: 'Access your personal shop dashboard to oversee performance and update inventory on MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/shop/dashboard`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/shop/dashboard` },
    { property: 'og:title', content: 'Shop Dashboard - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Access your personal shop dashboard to oversee performance and update inventory on MOM - Ultimate AI powered Marketplace.' },
  ]
}));

// Shop Requests Page
allPages.push(mappedMetaData({
  title: 'Customer Requests - MOM - Ultimate AI powered Marketplace',
  description: 'View and respond to customer service requests and inquiries in the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/shop/requests`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/shop/requests` },
    { property: 'og:title', content: 'Customer Requests - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'View and respond to customer service requests and inquiries in the MOM - Ultimate AI powered Marketplace.' },
  ]
}));

// Provider Login Page
allPages.push(mappedMetaData({
  title: 'Provider Login - MOM - Ultimate AI powered Marketplace',
  description: 'Service providers can log in to their MOM - Ultimate AI powered Marketplace profile to access and manage their offerings.',
  canonical: `${appUrl}/provider/login`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/provider/login` },
    { property: 'og:title', content: 'Provider Login - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Service providers can log in to their MOM - Ultimate AI powered Marketplace profile to access and manage their offerings.' },
  ]
}));

// Provider Registration Page
allPages.push(mappedMetaData({
  title: 'Provider Registration - MOM - Ultimate AI powered Marketplace',
  description: 'Sign up to offer your services on MOM - Ultimate AI powered Marketplace and connect with customers online.',
  canonical: `${appUrl}/provider/registration`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/provider/registration` },
    { property: 'og:title', content: 'Provider Registration - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Sign up to offer your services on MOM - Ultimate AI powered Marketplace and connect with customers online.' },
  ]
}));

// Provider Forgot Password Page
allPages.push(mappedMetaData({
  title: 'Reset Your Password - Provider - MOM - Ultimate AI powered Marketplace',
  description: 'Providers can reset their forgotten passwords to regain access to their MOM - Ultimate AI powered Marketplace accounts.',
  canonical: `${appUrl}/provider/forget-password`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/provider/forget-password` },
    { property: 'og:title', content: 'Reset Your Password - Provider - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Providers can reset their forgotten passwords to regain access to their MOM - Ultimate AI powered Marketplace accounts.' },
  ]
}));

// Provider Promocodes Page
allPages.push(mappedMetaData({
  title: 'Provider Promotions - MOM - Ultimate AI powered Marketplace',
  description: 'Manage your promocodes and special offers for customers on the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/provider/promocodes`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/provider/promocodes` },
    { property: 'og:title', content: 'Provider Promotions - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Manage your promocodes and special offers for customers on the MOM - Ultimate AI powered Marketplace.' },
  ]
}));

// Provider Profile Page
allPages.push(mappedMetaData({
  title: 'Your Profile - Provider - MOM - Ultimate AI powered Marketplace',
  description: 'Update your profile details and manage your service provider account on the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/provider/profile`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/provider/profile` },
    { property: 'og:title', content: 'Your Profile - Provider - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Update your profile details and manage your service provider account on the MOM - Ultimate AI powered Marketplace.' },
  ]
}));

// Provider Payment Configuration Page
allPages.push(mappedMetaData({
  title: 'Payment Configuration - Provider - MOM - Ultimate AI powered Marketplace',
  description: 'Configure your payment settings and preferences to ensure smooth transactions on the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/provider/payment-config`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/provider/payment-config` },
    { property: 'og:title', content: 'Payment Configuration - Provider - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Configure your payment settings and preferences to ensure smooth transactions on the MOM - Ultimate AI powered Marketplace.' },
  ]
}));

// Provider Wallet Page
allPages.push(mappedMetaData({
  title: 'Your Wallet - Provider - MOM - Ultimate AI powered Marketplace',
  description: 'Monitor your earnings, view transaction history, and manage your funds through your wallet on the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/provider/wallet`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/provider/wallet` },
    { property: 'og:title', content: 'Your Wallet - Provider - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Monitor your earnings, view transaction history, and manage your funds through your wallet on the MOM - Ultimate AI powered Marketplace.' },
  ]
}));

// Provider Analytics Page
allPages.push(mappedMetaData({
  title: 'Analytics - Provider - MOM - Ultimate AI powered Marketplace',
  description: 'Gain insights into your service performance with detailed analytics and reporting tools on the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/provider/analytics`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/provider/analytics` },
    { property: 'og:title', content: 'Analytics - Provider - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Gain insights into your service performance with detailed analytics and reporting tools on the MOM - Ultimate AI powered Marketplace.' },
  ]
}));

// Provider Agents Page
allPages.push(mappedMetaData({
  title: 'Your Agents - Provider - MOM - Ultimate AI powered Marketplace',
  description: 'Manage your team of agents, assign tasks, and track performance on the MOM - Ultimate AI powered Marketplace provider portal.',
  canonical: `${appUrl}/provider/agents`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/provider/agents` },
    { property: 'og:title', content: 'Your Agents - Provider - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Manage your team of agents, assign tasks, and track performance on the MOM - Ultimate AI powered Marketplace provider portal.' },
  ]
}));

// Provider Support Page
allPages.push(mappedMetaData({
  title: 'Provider Support - MOM - Ultimate AI powered Marketplace',
  description: 'Get assistance and support for any issues or inquiries you might have as a service provider on the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/provider/support`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/provider/support` },
    { property: 'og:title', content: 'Provider Support - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Get assistance and support for any issues or inquiries you might have as a service provider on the MOM - Ultimate AI powered Marketplace.' },
  ]
}));

// Provider Notifications Page
allPages.push(mappedMetaData({
  title: 'Notifications - Provider - MOM - Ultimate AI powered Marketplace',
  description: 'Stay up-to-date with the latest alerts, updates, and communications on your MOM - Ultimate AI powered Marketplace provider account.',
  canonical: `${appUrl}/provider/notifications`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/provider/notifications` },
    { property: 'og:title', content: 'Notifications - Provider - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Stay up-to-date with the latest alerts, updates, and communications on your MOM - Ultimate AI powered Marketplace provider account.' },
  ]
}));

// Provider Order Page
allPages.push(mappedMetaData({
  title: 'Manage Orders - Provider - MOM - Ultimate AI powered Marketplace',
  description: 'Review and manage customer orders and service requests quickly and efficiently on the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/provider/order`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/provider/order` },
    { property: 'og:title', content: 'Manage Orders - Provider - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Review and manage customer orders and service requests quickly and efficiently on the MOM - Ultimate AI powered Marketplace.' },
  ]
}));

// Provider Services Page
allPages.push(mappedMetaData({
  title: 'Your Services - Provider - MOM - Ultimate AI powered Marketplace',
  description: 'Explore services offered, adjust service details, and manage availability on the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/provider/services`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/provider/services` },
    { property: 'og:title', content: 'Your Services - Provider - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Explore services offered, adjust service details, and manage availability on the MOM - Ultimate AI powered Marketplace.' },
  ]
}));

// Provider My Documents Page
allPages.push(mappedMetaData({
  title: 'My Documents - Provider - MOM - Ultimate AI powered Marketplace',
  description: 'Securely upload, manage, and store important documents pertaining to your provider profile on the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/provider/my-documents`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/provider/my-documents` },
    { property: 'og:title', content: 'My Documents - Provider - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Securely upload, manage, and store important documents pertaining to your provider profile on the MOM - Ultimate AI powered Marketplace.' },
  ]
}));

// Provider My Services Page
allPages.push(mappedMetaData({
  title: 'My Services - Provider - MOM - Ultimate AI powered Marketplace',
  description: 'Access a summary of your active services, update your offerings, and manage bookings on the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/provider/my-services`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/provider/my-services` },
    { property: 'og:title', content: 'My Services - Provider - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Access a summary of your active services, update your offerings, and manage bookings on the MOM - Ultimate AI powered Marketplace.' },
  ]
}));

// Provider Requests Page
allPages.push(mappedMetaData({
  title: 'Service Requests - Provider - MOM - Ultimate AI powered Marketplace',
  description: 'Review and manage incoming service requests from customers on the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/provider/requests`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/provider/requests` },
    { property: 'og:title', content: 'Service Requests - Provider - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Review and manage incoming service requests from customers on the MOM - Ultimate AI powered Marketplace.' },
  ]
}));

// Provider Booking Page
allPages.push(mappedMetaData({
  title: 'Manage Bookings - Provider - MOM - Ultimate AI powered Marketplace',
  description: 'Keep track of all your appointments and manage bookings seamlessly on the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/provider/booking`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/provider/booking` },
    { property: 'og:title', content: 'Manage Bookings - Provider - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Keep track of all your appointments and manage bookings seamlessly on the MOM - Ultimate AI powered Marketplace.' },
  ]
}));

// Provider Agent Requests Page
allPages.push(mappedMetaData({
  title: 'Agent Service Requests - Provider - MOM - Ultimate AI powered Marketplace',
  description: 'Assign and oversee service requests for your agents on the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/provider/agent/requests`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/provider/agent/requests` },
    { property: 'og:title', content: 'Agent Service Requests - Provider - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Assign and oversee service requests for your agents on the MOM - Ultimate AI powered Marketplace.' },
  ]
}));

// Provider Agent Dashboard Page
allPages.push(mappedMetaData({
  title: 'Agent Dashboard - Provider - MOM - Ultimate AI powered Marketplace',
  description: 'Access your agent performance dashboard to monitor tasks and activity on the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/provider/agent/dashboard`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/provider/agent/dashboard` },
    { property: 'og:title', content: 'Agent Dashboard - Provider - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Access your agent performance dashboard to monitor tasks and activity on the MOM - Ultimate AI powered Marketplace.' },
  ]
}));

// Provider Reviews Page
allPages.push(mappedMetaData({
  title: 'Customer Reviews - Provider - MOM - Ultimate AI powered Marketplace',
  description: 'Read customer reviews and feedback to enhance your services on the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/provider/reviews`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/provider/reviews` },
    { property: 'og:title', content: 'Customer Reviews - Provider - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Read customer reviews and feedback to enhance your services on the MOM - Ultimate AI powered Marketplace.' },
  ]
}));

// Provider Overviews Page
allPages.push(mappedMetaData({
  title: 'Business Overview - Provider - MOM - Ultimate AI powered Marketplace',
  description: 'Get a comprehensive overview of your business performance metrics on the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/provider/overviews`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/provider/overviews` },
    { property: 'og:title', content: 'Business Overview - Provider - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Get a comprehensive overview of your business performance metrics on the MOM - Ultimate AI powered Marketplace.' },
  ]
}));

// Provider HRM Departments Page
allPages.push(mappedMetaData({
  title: 'HRM Departments - Provider - MOM - Ultimate AI powered Marketplace',
  description: 'Manage your organizational structure by setting up departments within your provider profile on the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/provider/hrm-departments`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/provider/hrm-departments` },
    { property: 'og:title', content: 'HRM Departments - Provider - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Manage your organizational structure by setting up departments within your provider profile on the MOM - Ultimate AI powered Marketplace.' },
  ]
}));

// Provider HRM Designation Page
allPages.push(mappedMetaData({
  title: 'HRM Designations - Provider - MOM - Ultimate AI powered Marketplace',
  description: 'Define and manage employee roles and designations within your organization on the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/provider/hrm-designation`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/provider/hrm-designation` },
    { property: 'og:title', content: 'HRM Designations - Provider - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Define and manage employee roles and designations within your organization on the MOM - Ultimate AI powered Marketplace.' },
  ]
}));

// Provider HRM Attendance Page
allPages.push(mappedMetaData({
  title: 'HRM Attendance - Provider - MOM - Ultimate AI powered Marketplace',
  description: 'Monitor and track your team’s attendance and working hours on the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/provider/hrm-attendance`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/provider/hrm-attendance` },
    { property: 'og:title', content: 'HRM Attendance - Provider - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Monitor and track your team’s attendance and working hours on the MOM - Ultimate AI powered Marketplace.' },
  ]
}));

// Provider HRM Holidays Page
allPages.push(mappedMetaData({
  title: 'HRM Holidays - Provider - MOM - Ultimate AI powered Marketplace',
  description: 'Schedule and manage official holidays and leave for your team on the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/provider/hrm-holidays`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/provider/hrm-holidays` },
    { property: 'og:title', content: 'HRM Holidays - Provider - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Schedule and manage official holidays and leave for your team on the MOM - Ultimate AI powered Marketplace.' },
  ]
}));

// Provider HRM Shift Page
allPages.push(mappedMetaData({
  title: 'HRM Shift Scheduling - Provider - MOM - Ultimate AI powered Marketplace',
  description: 'Organize and manage shift schedules for your employees on the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/provider/hrm-shift`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/provider/hrm-shift` },
    { property: 'og:title', content: 'HRM Shift Scheduling - Provider - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Organize and manage shift schedules for your employees on the MOM - Ultimate AI powered Marketplace.' },
  ]
}));

// Provider HRM Employees Page
allPages.push(mappedMetaData({
  title: 'HRM Employees - Provider - MOM - Ultimate AI powered Marketplace',
  description: 'View and manage all employee profiles, details, and records on the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/provider/hrm-employees`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/provider/hrm-employees` },
    { property: 'og:title', content: 'HRM Employees - Provider - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'View and manage all employee profiles, details, and records on the MOM - Ultimate AI powered Marketplace.' },
  ]
}));

// Store Page
allPages.push(mappedMetaData({
  title: 'Online Store - MOM - Ultimate AI powered Marketplace',
  description: 'Shop for products and services offered by our trusted providers on the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/store`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/store` },
    { property: 'og:title', content: 'Online Store - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Shop for products and services offered by our trusted providers on the MOM - Ultimate AI powered Marketplace.' },
  ]
}));

// Provider HRM Leaves Page
allPages.push(mappedMetaData({
  title: 'HRM Leave Management - Provider - MOM - Ultimate AI powered Marketplace',
  description: 'Manage leave requests and policies for your staff efficiently on the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/provider/hrm-leaves`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/provider/hrm-leaves` },
    { property: 'og:title', content: 'HRM Leave Management - Provider - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Manage leave requests and policies for your staff efficiently on the MOM - Ultimate AI powered Marketplace.' },
  ]
}));

// Type Page
allPages.push(mappedMetaData({
  title: 'Services by Type - MOM - Ultimate AI powered Marketplace',
  description: 'Explore different types of services available on the MOM - Ultimate AI powered Marketplace to find exactly what you need.',
  canonical: `${appUrl}/type`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/type` },
    { property: 'og:title', content: 'Services by Type - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Explore different types of services available on the MOM - Ultimate AI powered Marketplace to find exactly what you need.' },
  ]
}));

// Provider Photos Page
allPages.push(mappedMetaData({
  title: 'Provider Photo Gallery - MOM - Ultimate AI powered Marketplace',
  description: 'Showcase your work and services with a professional photo gallery on the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/provider/photos`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/provider/photos` },
    { property: 'og:title', content: 'Provider Photo Gallery - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Showcase your work and services with a professional photo gallery on the MOM - Ultimate AI powered Marketplace.' },
  ]
}));

// Provider Policies Page
allPages.push(mappedMetaData({
  title: 'Provider Policies - MOM - Ultimate AI powered Marketplace',
  description: 'Stay informed about the terms, policies, and guidelines for service providers on the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/provider/policies`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/provider/policies` },
    { property: 'og:title', content: 'Provider Policies - MOM - Ultimate AI powered Marketplace' },
    {
      property:

        'og:description', content: 'Stay informed about the terms, policies, and guidelines for service providers on the MOM - Ultimate AI powered Marketplace.'
    },
  ]
}));

// Shop Login Page
allPages.push(mappedMetaData({
  title: 'Login - Shop - MOM - Ultimate AI powered Marketplace',
  description: 'Access your shopping account to view orders and continue shopping on the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/shop/login`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/shop/login` },
    { property: 'og:title', content: 'Login - Shop - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Access your shopping account to view orders and continue shopping on the MOM - Ultimate AI powered Marketplace.' },
  ]
}));

// Shop Registration Page
allPages.push(mappedMetaData({
  title: 'Registration - Shop - MOM - Ultimate AI powered Marketplace',
  description: 'Create a new shopping account to start placing orders and accessing exclusive deals on the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/shop/registration`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/shop/registration` },
    { property: 'og:title', content: 'Registration - Shop - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Create a new shopping account to start placing orders and accessing exclusive deals on the MOM - Ultimate AI powered Marketplace.' },
  ]
}));

// Shop Forget Password Page
allPages.push(mappedMetaData({
  title: 'Forgot Password - Shop - MOM - Ultimate AI powered Marketplace',
  description: 'Reset your forgotten password to regain access to your shopping account on the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/shop/forget-password`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/shop/forget-password` },
    { property: 'og:title', content: 'Forgot Password - Shop - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Reset your forgotten password to regain access to your shopping account on the MOM - Ultimate AI powered Marketplace.' },
  ]
}));

// Shop Change Password Page
allPages.push(mappedMetaData({
  title: 'Change Password - Shop - MOM - Ultimate AI powered Marketplace',
  description: 'Secure your shopping account by updating your password on the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/shop/change-password`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/shop/change-password` },
    { property: 'og:title', content: 'Change Password - Shop - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Secure your shopping account by updating your password on the MOM - Ultimate AI powered Marketplace.' },
  ]
}));

// Shop Analytics Page
allPages.push(mappedMetaData({
  title: 'Shop Analytics - MOM - Ultimate AI powered Marketplace',
  description: 'Gain insights into your shopping behavior and preferences with analytics on the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/shop/analytics`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/shop/analytics` },
    { property: 'og:title', content: 'Shop Analytics - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Gain insights into your shopping behavior and preferences with analytics on the MOM - Ultimate AI powered Marketplace.' },
  ]
}));

// Shop Dispatcher Page
allPages.push(mappedMetaData({
  title: 'Shop Dispatcher - MOM - Ultimate AI powered Marketplace',
  description: 'Oversee your delivery operations and dispatch orders efficiently on the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/shop/dispatcher`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/shop/dispatcher` },
    { property: 'og:title', content: 'Shop Dispatcher - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Oversee your delivery operations and dispatch orders efficiently on the MOM - Ultimate AI powered Marketplace.' },
  ]
}));

// Shop My Documents Page
allPages.push(mappedMetaData({
  title: 'My Documents - Shop - MOM - Ultimate AI powered Marketplace',
  description: 'Store and access your important shopping documents in one place on the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/shop/my-documents`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/shop/my-documents` },
    { property: 'og:title', content: 'My Documents - Shop - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Store and access your important shopping documents in one place on the MOM - Ultimate AI powered Marketplace.' },
  ]
}));

// Shop Bank Details Page
allPages.push(mappedMetaData({
  title: 'Bank Details - Shop - MOM - Ultimate AI powered Marketplace',
  description: 'Manage your banking information to ensure secure transactions on the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/shop/bank-details`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/shop/bank-details` },
    { property: 'og:title', content: 'Bank Details - Shop - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Manage your banking information to ensure secure transactions on the MOM - Ultimate AI powered Marketplace.' },
  ]
}));

// Shop Profile Page
allPages.push(mappedMetaData({
  title: 'Profile - Shop - MOM - Ultimate AI powered Marketplace',
  description: 'Edit and update your personal and shopping profile details on the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/shop/profile`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/shop/profile` },
    { property: 'og:title', content: 'Profile - Shop - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Edit and update your personal and shopping profile details on the MOM - Ultimate AI powered Marketplace.' },
  ]
}));

// Shop Promocodes Page
allPages.push(mappedMetaData({
  title: 'Promocodes - Shop - MOM - Ultimate AI powered Marketplace',
  description: 'Discover and apply promotional codes to get discounts and special offers on your purchases at the MOM - Ultimate AI powered Marketplace shop.',
  canonical: `${appUrl}/shop/promocodes`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/shop/promocodes` },
    { property: 'og:title', content: 'Promocodes - Shop - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Discover and apply promotional codes to get discounts and special offers on your purchases at the MOM - Ultimate AI powered Marketplace shop.' },
  ]
}));

// Shop Category Page
allPages.push(mappedMetaData({
  title: 'Shop by Category - MOM - Ultimate AI powered Marketplace',
  description: 'Explore products organized by categories to find exactly what you need in the MOM - Ultimate AI powered Marketplace shop.',
  canonical: `${appUrl}/shop/category`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/shop/category` },
    { property: 'og:title', content: 'Shop by Category - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Explore products organized by categories to find exactly what you need in the MOM - Ultimate AI powered Marketplace shop.' },
  ]
}));

// Shop Variations Page
allPages.push(mappedMetaData({
  title: 'Product Variations - Shop - MOM - Ultimate AI powered Marketplace',
  description: 'Choose from a variety of product options such as size, color, and more on the MOM - Ultimate AI powered Marketplace shop.',
  canonical: `${appUrl}/shop/variations`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/shop/variations` },
    { property: 'og:title', content: 'Product Variations - Shop - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Choose from a variety of product options such as size, color, and more on the MOM - Ultimate AI powered Marketplace shop.' },
  ]
}));

// Shop Agents Page
allPages.push(mappedMetaData({
  title: 'Agents - Shop - MOM - Ultimate AI powered Marketplace',
  description: 'Connect with our qualified agents to assist you with your shopping experience on the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/shop/agents`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/shop/agents` },
    { property: 'og:title', content: 'Agents - Shop - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Connect with our qualified agents to assist you with your shopping experience on the MOM - Ultimate AI powered Marketplace.' },
  ]
}));

// Shop Agents Dashboard Page
allPages.push(mappedMetaData({
  title: 'Agent Dashboard - Shop - MOM - Ultimate AI powered Marketplace',
  description: 'Access your agent dashboard to manage tasks, orders, and customer interactions on the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/shop/agents-dashboard`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/shop/agents-dashboard` },
    { property: 'og:title', content: 'Agent Dashboard - Shop - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Access your agent dashboard to manage tasks, orders, and customer interactions on the MOM - Ultimate AI powered Marketplace.' },
  ]
}));

// Shop Delivery Settings Page
allPages.push(mappedMetaData({
  title: 'Delivery Settings - Shop - MOM - Ultimate AI powered Marketplace',
  description: 'Customize your delivery preferences and settings for optimal convenience on the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/shop/delivery-settings`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/shop/delivery-settings` },
    { property: 'og:title', content: 'Delivery Settings - Shop - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Customize your delivery preferences and settings for optimal convenience on the MOM - Ultimate AI powered Marketplace.' },
  ]
}));

// Shop Notifications Page
allPages.push(mappedMetaData({
  title: 'Notifications - Shop - MOM - Ultimate AI powered Marketplace',
  description: 'Stay up-to-date with the latest alerts, updates, and promotional offers from the MOM - Ultimate AI powered Marketplace shop.',
  canonical: `${appUrl}/shop/notifications`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/shop/notifications` },
    { property: 'og:title', content: 'Notifications - Shop - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Stay up-to-date with the latest alerts, updates, and promotional offers from the MOM - Ultimate AI powered Marketplace shop.' },
  ]
}));

// Shop Reviews Page
allPages.push(mappedMetaData({
  title: 'Product Reviews - Shop - MOM - Ultimate AI powered Marketplace',
  description: 'Read and write reviews for products to share your experience with the MOM - Ultimate AI powered Marketplace community.',
  canonical: `${appUrl}/shop/reviews`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/shop/reviews` },
    { property: 'og:title', content: 'Product Reviews - Shop - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Read and write reviews for products to share your experience with the MOM - Ultimate AI powered Marketplace community.' },
  ]
}));

// Shop Messaging Page
allPages.push(mappedMetaData({
  title: 'Messaging - Shop - MOM - Ultimate AI powered Marketplace',
  description: 'Communicate directly with sellers, agents, and customer service using the MOM - Ultimate AI powered Marketplace messaging system.',
  canonical: `${appUrl}/shop/messaging`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/shop/messaging` },
    { property: 'og:title', content: 'Messaging - Shop - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Communicate directly with sellers, agents, and customer service using the MOM - Ultimate AI powered Marketplace messaging system.' },
  ]
}));

// Shop Booking Page
allPages.push(mappedMetaData({
  title: 'Booking - Shop - MOM - Ultimate AI powered Marketplace',
  description: 'Book services and schedule appointments with ease using the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/shop/booking`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/shop/booking` },
    { property: 'og:title', content: 'Booking - Shop - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Book services and schedule appointments with ease using the MOM - Ultimate AI powered Marketplace.' },
  ]
}));

// Shop Addons Page
allPages.push(mappedMetaData({
  title: 'Shop Add-ons - MOM - Ultimate AI powered Marketplace',
  description: 'Enhance your shopping experience with additional features and tools available on the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/shop/addons`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/shop/addons` },
    { property: 'og:title', content: 'Shop Add-ons - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Enhance your shopping experience with additional features and tools available on the MOM - Ultimate AI powered Marketplace.' },
  ]
}));

// Shop User Reviews Page
allPages.push(mappedMetaData({
  title: 'User Reviews - Shop - MOM - Ultimate AI powered Marketplace',
  description: 'Check user reviews to make informed decisions before purchasing products on the MOM - Ultimate AI powered Marketplace shop.',
  canonical: `${appUrl}/shop/user-reviews`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/shop/user-reviews` },
    { property: 'og:title', content: 'User Reviews - Shop - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Check user reviews to make informed decisions before purchasing products on the MOM - Ultimate AI powered Marketplace shop.' },
  ]
}));

// Shop Overviews Page
allPages.push(mappedMetaData({
  title: 'Shop Overview - MOM - Ultimate AI powered Marketplace',
  description: 'Get a comprehensive overview of the products, services, and features offered in the MOM - Ultimate AI powered Marketplace shop.',
  canonical: `${appUrl}/shop/overviews`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/shop/overviews` },
    { property: 'og:title', content: 'Shop Overview - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Get a comprehensive overview of the products, services, and features offered in the MOM - Ultimate AI powered Marketplace shop.' },
  ]
}));

// Shop Photos Page
allPages.push(mappedMetaData({
  title: 'Shop Photos - MOM - Ultimate AI powered Marketplace',
  description: 'Browse through our gallery of product photos to visualize what you’re shopping for on the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/shop/photos`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/shop/photos` },
    { property: 'og:title', content: 'Shop Photos - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Browse through our gallery of product photos to visualize what you’re shopping for on the MOM - Ultimate AI powered Marketplace.' },
  ]
}));

// Shop Items Page
allPages.push(mappedMetaData({
  title: 'Shop Items - MOM - Ultimate AI powered Marketplace',
  description: 'Explore our diverse range of items and find everything you need in one place on the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/shop/items`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/shop/items` },
    { property: 'og:title', content: 'Shop Items - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Explore our diverse range of items and find everything you need in one place on the MOM - Ultimate AI powered Marketplace.' },
  ]
}));

// Shop Wallet Page
allPages.push(mappedMetaData({
  title: 'Your Wallet - Shop - MOM - Ultimate AI powered Marketplace',
  description: 'Manage your store credit, gift cards, and transactions easily with your personal wallet on the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/shop/wallet`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/shop/wallet` },
    { property: 'og:title', content: 'Your Wallet - Shop - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Manage your store credit, gift cards, and transactions easily with your personal wallet on the MOM - Ultimate AI powered Marketplace.' },
  ]
}));

// Shop Order History Page
allPages.push(mappedMetaData({
  title: 'Order History - Shop - MOM - Ultimate AI powered Marketplace',
  description: 'Review your past orders and keep track of your purchases with the order history feature on the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/shop/order-history`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/shop/order-history` },
    { property: 'og:title', content: 'Order History - Shop - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Review your past orders and keep track of your purchases with the order history feature on the MOM - Ultimate AI powered Marketplace.' },
  ]
}));

// Shop Order Statement Page
allPages.push(mappedMetaData({
  title: 'Order Statement - Shop - MOM - Ultimate AI powered Marketplace',
  description: 'Get detailed statements of your orders to monitor spending and order statuses on the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/shop/order-statement`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/shop/order-statement` },
    { property: 'og:title', content: 'Order Statement - Shop - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Get detailed statements of your orders to monitor spending and order statuses on the MOM - Ultimate AI powered Marketplace.' },
  ]
}));

// Shop Shop Statement Page
allPages.push(mappedMetaData({
  title: 'Shop Statement - MOM - Ultimate AI powered Marketplace',
  description: 'Access financial statements for your shop to keep an overview of your sales and earnings on the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/shop/shop-statement`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/shop/shop-statement` },
    { property: 'og:title', content: 'Shop Statement - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Access financial statements for your shop to keep an overview of your sales and earnings on the MOM - Ultimate AI powered Marketplace.' },
  ]
}));

// Shop HRM Departments Page
allPages.push(mappedMetaData({
  title: 'HRM Departments - Shop - MOM - Ultimate AI powered Marketplace',
  description: 'Organize and manage your human resources by departments within your shop on the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/shop/hrm-departments`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/shop/hrm-departments` },
    { property: 'og:title', content: 'HRM Departments - Shop - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Organize and manage your human resources by departments within your shop on the MOM - Ultimate AI powered Marketplace.' },
  ]
}));

// Shop HRM Designation Page
allPages.push(mappedMetaData({
  title: 'HRM Designations - Shop - MOM - Ultimate AI powered Marketplace',
  description: 'Define roles and responsibilities by assigning designations to your team members on the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/shop/hrm-designation`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/shop/hrm-designation` },
    { property: 'og:title', content: 'HRM Designations - Shop - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Define roles and responsibilities by assigning designations to your team members on the MOM - Ultimate AI powered Marketplace.' },
  ]
}));

// Shop HRM Attendance Page
allPages.push(mappedMetaData({
  title: 'HRM Attendance - Shop - MOM - Ultimate AI powered Marketplace',
  description: 'Monitor and record the attendance of your employees to manage staffing needs efficiently on the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/shop/hrm-attendance`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/shop/hrm-attendance` },
    { property: 'og:title', content: 'HRM Attendance - Shop - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Monitor and record the attendance of your employees to manage staffing needs efficiently on the MOM - Ultimate AI powered Marketplace.' },
  ]
}));

// Shop HRM Holidays Page
allPages.push(mappedMetaData({
  title: 'HRM Holidays - Shop - MOM - Ultimate AI powered Marketplace',
  description: 'Manage holidays and leave days for your workforce to ensure smooth operation of your shop on the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/shop/hrm-holidays`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/shop/hrm-holidays` },
    { property: 'og:title', content: 'HRM Holidays - Shop - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Manage holidays and leave days for your workforce to ensure smooth operation of your shop on the MOM - Ultimate AI powered Marketplace.' },
  ]
}));

// Shop HRM Shift Page
allPages.push(mappedMetaData({
  title: 'HRM Shift Scheduling - Shop - MOM - Ultimate AI powered Marketplace',
  description: 'Plan and manage your employees\' shift schedules to ensure your shop operates efficiently on the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/shop/hrm-shift`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/shop/hrm-shift` },
    { property: 'og:title', content: 'HRM Shift Scheduling - Shop - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Plan and manage your employees\' shift schedules to ensure your shop operates efficiently on the MOM - Ultimate AI powered Marketplace.' },
  ]
}));

// Shop HRM Employees Page
allPages.push(mappedMetaData({
  title: 'HRM Employees - Shop - MOM - Ultimate AI powered Marketplace',
  description: 'Manage your employee profiles and keep track of your workforce details with HRM on the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/shop/hrm-employees`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/shop/hrm-employees` },
    { property: 'og:title', content: 'HRM Employees - Shop - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Manage your employee profiles and keep track of your workforce details with HRM on the MOM - Ultimate AI powered Marketplace.' },
  ]
}));

// Shop HRM Leaves Page
allPages.push(mappedMetaData({
  title: 'HRM Leave Management - Shop - MOM - Ultimate AI powered Marketplace',
  description: 'Handle leave requests and track employee absence for smoother personnel management on the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/shop/hrm-leaves`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/shop/hrm-leaves` },
    { property: 'og:title', content: 'HRM Leave Management - Shop - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Handle leave requests and track employee absence for smoother personnel management on the MOM - Ultimate AI powered Marketplace.' },
  ]
}));

// Shop HRM Leave Types Page
allPages.push(mappedMetaData({
  title: 'HRM Leave Types - Shop - MOM - Ultimate AI powered Marketplace',
  description: 'Set up and customize leave types to cater to your shop\'s policy on the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/shop/hrm-leave-types`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/shop/hrm-leave-types` },
    { property: 'og:title', content: 'HRM Leave Types - Shop - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Set up and customize leave types to cater to your shop\'s policy on the MOM - Ultimate AI powered Marketplace.' },
  ]
}));

// Shop Payment Config Page
allPages.push(mappedMetaData({
  title: 'Payment Configuration - Shop - MOM - Ultimate AI powered Marketplace',
  description: 'Configure your payment gateways and settings to facilitate smooth transactions on the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/shop/payment-config`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/shop/payment-config` },
    { property: 'og:title', content: 'Payment Configuration - Shop - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Configure your payment gateways and settings to facilitate smooth transactions on the MOM - Ultimate AI powered Marketplace.' },
  ]
}));

// Shop Policies Page
allPages.push(mappedMetaData({
  title: 'Shop Policies - MOM - Ultimate AI powered Marketplace',
  description: 'Stay informed about our shop policies including returns, shipping, and privacy on the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/shop/policies`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/shop/policies` },
    { property: 'og:title', content: 'Shop Policies - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Stay informed about our shop policies including returns, shipping, and privacy on the MOM - Ultimate AI powered Marketplace.' },
  ]
}));

// User Login Page
allPages.push(mappedMetaData({
  title: 'User Login - MOM - Ultimate AI powered Marketplace',
  description: 'Log in to access your personal user account, track orders, and enjoy a personalized shopping experience on the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/user/login`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/user/login` },
    { property: 'og:title', content: 'User Login - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Log in to access your personal user account, track orders, and enjoy a personalized shopping experience on the MOM - Ultimate AI powered Marketplace.' },
  ]
}));

// User Forget Password Page
allPages.push(mappedMetaData({
  title: 'Forgot Password - User - MOM - Ultimate AI powered Marketplace',
  description: 'Easily reset your password if you have forgotten it to regain access to your account on the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/user/forget-password`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/user/forget-password` },
    { property: 'og:title', content: 'Forgot Password - User - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Easily reset your password if you have forgotten it to regain access to your account on the MOM - Ultimate AI powered Marketplace.' },
  ]
}));

// User Registration Page
allPages.push(mappedMetaData({
  title: 'User Registration - MOM - Ultimate AI powered Marketplace',
  description: 'Create a new user account to start shopping, track orders, and get exclusive offers on the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/user/registration`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/user/registration` },
    { property: 'og:title', content: 'User Registration - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Create a new user account to start shopping, track orders, and get exclusive offers on the MOM - Ultimate AI powered Marketplace.' },
  ]
}));

// User Support Page
allPages.push(mappedMetaData({
  title: 'User Support - MOM - Ultimate AI powered Marketplace',
  description: 'Get help with any issues or questions you may have by reaching out to our user support team on the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/user/support`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/user/support` },
    { property: 'og:title', content: 'User Support - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Get help with any issues or questions you may have by reaching out to our user support team on the MOM - Ultimate AI powered Marketplace.' },
  ]
}));

// User Home Page
allPages.push(mappedMetaData({
  title: 'Home - User - MOM - Ultimate AI powered Marketplace',
  description: 'Welcome to your home on the MOM - Ultimate AI powered Marketplace - start browsing, shopping, and managing your account here.',
  canonical: `${appUrl}/user/home`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/user/home` },
    { property: 'og:title', content: 'Home - User - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Welcome to your home on the MOM - Ultimate AI powered Marketplace - start browsing, shopping, and managing your account here.' },
  ]
}));

// User Base Page
allPages.push(mappedMetaData({
  title: 'User Account - MOM - Ultimate AI powered Marketplace',
  description: 'Access your user account and personalize your MOM - Ultimate AI powered Marketplace experience. Manage your orders, profile, and settings all in one place.',
  canonical: `${appUrl}/en/user`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/en/user` },
    { property: 'og:title', content: 'User Account - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Access your user account and personalize your MOM - Ultimate AI powered Marketplace experience. Manage your orders, profile, and settings all in one place.' },
  ]
}));

// User Base Page
allPages.push(mappedMetaData({
  title: 'User Account - MOM - Ultimate AI powered Marketplace',
  description: 'Access your user account and personalize your MOM - Ultimate AI powered Marketplace experience. Manage your orders, profile, and settings all in one place.',
  canonical: `${appUrl}/en/user`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/user` },
    { property: 'og:title', content: 'User Account - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Access your user account and personalize your MOM - Ultimate AI powered Marketplace experience. Manage your orders, profile, and settings all in one place.' },
  ]
}));
// User Dashboard Page
allPages.push(mappedMetaData({
  title: 'Dashboard - User - MOM - Ultimate AI powered Marketplace',
  description: 'View your user dashboard to track your recent activity, orders, and personal recommendations on the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/user/dashboard`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/user/dashboard` },
    { property: 'og:title', content: 'Dashboard - User - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'View your user dashboard to track your recent activity, orders, and personal recommendations on the MOM - Ultimate AI powered Marketplace.' },
  ]
}));

// User Transport Page
allPages.push(mappedMetaData({
  title: 'Transport Options - User - MOM - Ultimate AI powered Marketplace',
  description: 'Choose your preferred transport options for your orders on the MOM - Ultimate AI powered Marketplace for a seamless delivery experience.',
  canonical: `${appUrl}/user/transport`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/user/transport` },
    { property: 'og:title', content: 'Transport Options - User - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Choose your preferred transport options for your orders on the MOM - Ultimate AI powered Marketplace for a seamless delivery experience.' },
  ]
}));

// User Delivery Page
allPages.push(mappedMetaData({
  title: 'Delivery Settings - User - MOM - Ultimate AI powered Marketplace',
  description: 'Manage your delivery address details and preferences to ensure accurate and timely delivery of your purchases on the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/user/delivery`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/user/delivery` },
    { property: 'og:title', content: 'Delivery Settings - User - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Manage your delivery address details and preferences to ensure accurate and timely delivery of your purchases on the MOM - Ultimate AI powered Marketplace.' },
  ]
}));

// User Profile Page
allPages.push(mappedMetaData({
  title: 'Profile - User - MOM - Ultimate AI powered Marketplace',
  description: 'Edit and update your personal profile information to keep your MOM - Ultimate AI powered Marketplace account up-to-date.',
  canonical: `${appUrl}/user/profile`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/user/profile` },
    { property: 'og:title', content: 'Profile - User - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Edit and update your personal profile information to keep your MOM - Ultimate AI powered Marketplace account up-to-date.' },
  ]
}));

// User Wallet Page
allPages.push(mappedMetaData({
  title: 'Wallet - User - MOM - Ultimate AI powered Marketplace',
  description: 'Monitor and manage your wallet balance, add credit, and view transaction history on the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/user/wallet`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/user/wallet` },
    { property: 'og:title', content: 'Wallet - User - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Monitor and manage your wallet balance, add credit, and view transaction history on the MOM - Ultimate AI powered Marketplace.' },
  ]
}));

// User Service Checkout Page
allPages.push(mappedMetaData({
  title: 'Service Checkout - User - MOM - Ultimate AI powered Marketplace',
  description: 'Complete your service requests by checking out with our secure payment gateway on the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/user/service/checkout`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/user/service/checkout` },
    { property: 'og:title', content: 'Service Checkout - User - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Complete your service requests by checking out with our secure payment gateway on the MOM - Ultimate AI powered Marketplace.' },
  ]
}));

// User Messaging Page
allPages.push(mappedMetaData({
  title: 'Messaging - User - MOM - Ultimate AI powered Marketplace',
  description: 'Communicate with sellers, support, and other users through a secure messaging platform on the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/user/messaging`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/user/messaging` },
    { property: 'og:title', content: 'Messaging - User - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Communicate with sellers, support, and other users through a secure messaging platform on the MOM - Ultimate AI powered Marketplace.' },
  ]
}));

// User AI Chat Page
allPages.push(mappedMetaData({
  title: 'AI Chat - User - MOM - Ultimate AI powered Marketplace',
  description: 'Get instant assistance and support by chatting with our AI-powered virtual assistant on the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/user/ai/chat`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/user/ai/chat` },
    { property: 'og:title', content: 'AI Chat - User - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Get instant assistance and support by chatting with our AI-powered virtual assistant on the MOM - Ultimate AI powered Marketplace.' },
  ]
}));

// User AI Images Page
allPages.push(mappedMetaData({
  title: 'AI Image Generation - User - MOM - Ultimate AI powered Marketplace',
  description: 'Create and explore AI-generated images tailored to your preferences on the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/user/ai/images`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/user/ai/images` },
    { property: 'og:title', content: 'AI Image Generation - User - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Create and explore AI-generated images tailored to your preferences on the MOM - Ultimate AI powered Marketplace.' },
  ]
}));

// User AI Saved Images Page
allPages.push(mappedMetaData({
  title: 'Saved AI Images - User - MOM - Ultimate AI powered Marketplace',
  description: 'Access your collection of saved AI-generated images on the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/user/ai/images/saved`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/user/ai/images/saved` },
    { property: 'og:title', content: 'Saved AI Images - User - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Access your collection of saved AI-generated images on the MOM - Ultimate AI powered Marketplace.' },
  ]
}));

// User AI Code Page
allPages.push(mappedMetaData({
  title: 'AI Code Assistance - User - MOM - Ultimate AI powered Marketplace',
  description: 'Leverage AI to generate code snippets and solve programming challenges on the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/user/ai/code`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/user/ai/code` },
    { property: 'og:title', content: 'AI Code Assistance - User - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Leverage AI to generate code snippets and solve programming challenges on the MOM - Ultimate AI powered Marketplace.' },
  ]
}));

// User AI Text-to-Speech Page
allPages.push(mappedMetaData({
  title: 'Text-to-Speech - User - MOM - Ultimate AI powered Marketplace',
  description: 'Convert your text into natural-sounding speech using advanced AI text-to-speech technology on the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/user/ai/text-to-speech`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/user/ai/text-to-speech` },
    { property: 'og:title', content: 'Text-to-Speech - User - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Convert your text into natural-sounding speech using advanced AI text-to-speech technology on the MOM - Ultimate AI powered Marketplace.' },
  ]
}));

// User AI Speech-to-Text Page
allPages.push(mappedMetaData({
  title: 'Speech-to-Text - User - MOM - Ultimate AI powered Marketplace',
  description: 'Transcribe your audio into text with high accuracy using AI speech-to-text on the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/user/ai/speech-to-text`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/user/ai/speech-to-text` },
    { property: 'og:title', content: 'Speech-to-Text - User - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Transcribe your audio into text with high accuracy using AI speech-to-text on the MOM - Ultimate AI powered Marketplace.' },
  ]
}));

// User AI Writer Page
allPages.push(mappedMetaData({
  title: 'AI Writer - User - MOM - Ultimate AI powered Marketplace',
  description: 'Create content effortlessly with the help of an AI-powered writing assistant on the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/user/ai/writer`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/user/ai/writer` },
    { property: 'og:title', content: 'AI Writer - User - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Create content effortlessly with the help of an AI-powered writing assistant on the MOM - Ultimate AI powered Marketplace.' },
  ]
}));


// Shop Homepage
allPages.push(mappedMetaData({
  title: 'Online Shop - MOM - Ultimate AI powered Marketplace',
  description: 'Browse and purchase your favorite products from a wide range of categories on the MOM - Ultimate AI powered Marketplace shop.',
  canonical: `${appUrl}/en/shop`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/en/shop` },
    { property: 'og:title', content: 'Online Shop - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Browse and purchase your favorite products from a wide range of categories on the MOM - Ultimate AI powered Marketplace shop.' },
  ]
}));

// Shop Main Homepage
allPages.push(mappedMetaData({
  title: 'Online Shop - MOM - Ultimate AI powered Marketplace',
  description: 'Browse and purchase your favorite products from a wide range of categories on the MOM - Ultimate AI powered Marketplace shop.',
  canonical: `${appUrl}/en/shop`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/shop` },
    { property: 'og:title', content: 'Online Shop - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Browse and purchase your favorite products from a wide range of categories on the MOM - Ultimate AI powered Marketplace shop.' },
  ]
}));

// Provider Page
allPages.push(mappedMetaData({
  title: 'Service Providers - MOM - Ultimate AI powered Marketplace',
  description: 'Connect with a network of skilled service providers and find the right professional for your needs on the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/en/provider`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/en/provider` },
    { property: 'og:title', content: 'Service Providers - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Connect with a network of skilled service providers and find the right professional for your needs on the MOM - Ultimate AI powered Marketplace.' },
  ]
}));

// Provider Main Page
allPages.push(mappedMetaData({
  title: 'Service Providers - MOM - Ultimate AI powered Marketplace',
  description: 'Connect with a network of skilled service providers and find the right professional for your needs on the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/en/provider`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/provider` },
    { property: 'og:title', content: 'Service Providers - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Connect with a network of skilled service providers and find the right professional for your needs on the MOM - Ultimate AI powered Marketplace.' },
  ]
}));
// Terms and Conditions Page
allPages.push(mappedMetaData({
  title: 'Terms and Conditions - MOM - Ultimate AI powered Marketplace',
  description: 'Read the terms and conditions to understand the legal agreements related to using the services on the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/terms-and-condition`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/terms-and-condition` },
    { property: 'og:title', content: 'Terms and Conditions - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Read the terms and conditions to understand the legal agreements related to using the services on the MOM - Ultimate AI powered Marketplace.' },
  ]
}));

// About Us Page
allPages.push(mappedMetaData({
  title: 'About Us - MOM - Ultimate AI powered Marketplace',
  description: 'Learn more about the MOM - Ultimate AI powered Marketplace, our mission, values, and the team dedicated to providing you with the best experience.',
  canonical: `${appUrl}/about-us`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/about-us` },
    { property: 'og:title', content: 'About Us - MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Learn more about the MOM - Ultimate AI powered Marketplace, our mission, values, and the team dedicated to providing you with the best experience.' },
  ]
}));

// Contact Us Page
allPages.push(mappedMetaData({
  title: 'Contact Us - Connect with MOM - Ultimate AI powered Marketplace Support',
  description: 'Have questions or need assistance? Reach out to the MOM - Ultimate AI powered Marketplace team via our contact page for support.',
  canonical: `${appUrl}/contact-us`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/contact-us` },
    { property: 'og:title', content: 'Contact Us - Connect with MOM - Ultimate AI powered Marketplace Support' },
    { property: 'og:description', content: 'Have questions or need assistance? Reach out to the MOM - Ultimate AI powered Marketplace team via our contact page for support.' },
  ]
}));

// Frequently Asked Questions (FAQ) Page
allPages.push(mappedMetaData({
  title: 'FAQ - Get Answers to Common Questions on MOM - Ultimate AI powered Marketplace',
  description: 'Find answers to frequently asked questions about using the MOM - Ultimate AI powered Marketplace, orders, services, and account management.',
  canonical: `${appUrl}/faq`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/faq` },
    { property: 'og:title', content: 'FAQ - Get Answers to Common Questions on MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Find answers to frequently asked questions about using the MOM - Ultimate AI powered Marketplace, orders, services, and account management.' },
  ]
}));

// Knowledge Base Page
allPages.push(mappedMetaData({
  title: 'Knowledge Base - Learn More About MOM - Ultimate AI powered Marketplace Services',
  description: 'Access our comprehensive knowledge base for in-depth articles, guides, and helpful resources about the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/knowledge-base`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/knowledge-base` },
    { property: 'og:title', content: 'Knowledge Base - Learn More About MOM - Ultimate AI powered Marketplace Services' },
    { property: 'og:description', content: 'Access our comprehensive knowledge base for in-depth articles, guides, and helpful resources about the MOM - Ultimate AI powered Marketplace.' },
  ]
}));

// Blog Page
allPages.push(mappedMetaData({
  title: 'Blog - Latest News, Tips, and Stories on MOM - Ultimate AI powered Marketplace',
  description: 'Dive into the MOM - Ultimate AI powered Marketplace blog for the latest updates, insights, and stories to enhance your shopping and service experience.',
  canonical: `${appUrl}/blog`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/blog` },
    { property: 'og:title', content: 'Blog - Latest News, Tips, and Stories on MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'Dive into the MOM - Ultimate AI powered Marketplace blog for the latest updates, insights, and stories to enhance your shopping and service experience.' },
  ]
}));

// Error 404 Page
allPages.push(mappedMetaData({
  title: 'Error 404 - Page Not Found on MOM - Ultimate AI powered Marketplace',
  description: 'The page you are trying to access cannot be found. Use our navigation to get back on track on the MOM - Ultimate AI powered Marketplace.',
  canonical: `${appUrl}/error-404`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/error-404` },
    { property: 'og:title', content: 'Error 404 - Page Not Found on MOM - Ultimate AI powered Marketplace' },
    { property: 'og:description', content: 'The page you are trying to access cannot be found. Use our navigation to get back on track on the MOM - Ultimate AI powered Marketplace.' },
  ]
}));

// Hindi
allPages.push(mappedMetaData({
  title: 'सेवा प्रदाता - MOM - अंतिम एआई सशक्त बाजार',
  description: 'MOM - अंतिम एआई सशक्त बाजार पर एक कुशल सेवा प्रदाताओं का नेटवर्क से जुड़ें और अपनी आवश्यकताओं के लिए सही पेशेवर को खोजें।',
  canonical: `${appUrl}/hi/provider`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/hi/provider` },
    { property: 'og:title', content: 'सेवा प्रदाता - MOM - अंतिम एआई सशक्त बाजार' },
    { property: 'og:description', content: 'MOM - अंतिम एआई सशक्त बाजार पर एक कुशल सेवा प्रदाताओं का नेटवर्क से जुड़ें और अपनी आवश्यकताओं के लिए सही पेशेवर को खोजें।' },
  ]
}));

// Arabic
allPages.push(mappedMetaData({
  title: 'مزودو الخدمة - MOM - السوق النهائي المدعوم بالذكاء الصناعي',
  description: 'اتصل بشبكة من مزودي الخدمات الماهرين وابحث عن المحترف المناسب لاحتياجاتك على MOM - السوق النهائي المدعوم بالذكاء الصناعي.',
  canonical: `${appUrl}/ar/provider`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/ar/provider` },
    { property: 'og:title', content: 'مزودو الخدمة - MOM - السوق النهائي المدعوم بالذكاء الصناعي' },
    { property: 'og:description', content: 'اتصل بشبكة من مزودي الخدمات الماهرين وابحث عن المحترف المناسب لاحتياجاتك على MOM - السوق النهائي المدعوم بالذكاء الصناعي.' },
  ]
}));

// German
allPages.push(mappedMetaData({
  title: 'Dienstleister - MOM - Ultimativer KI-gesteuerter Marktplatz',
  description: 'Verbinden Sie sich mit einem Netzwerk von erfahrenen Dienstleistern und finden Sie den richtigen Profi für Ihre Bedürfnisse auf dem MOM - Ultimativer KI-gesteuerter Marktplatz.',
  canonical: `${appUrl}/de/provider`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/de/provider` },
    { property: 'og:title', content: 'Dienstleister - MOM - Ultimativer KI-gesteuerter Marktplatz' },
    { property: 'og:description', content: 'Verbinden Sie sich mit einem Netzwerk von erfahrenen Dienstleistern und finden Sie den richtigen Profi für Ihre Bedürfnisse auf dem MOM - Ultimativer KI-gesteuerter Marktplatz.' },
  ]
}));

// Spanish
allPages.push(mappedMetaData({
  title: 'Proveedores de servicios - MOM - Mercado final impulsado por IA',
  description: 'Conéctese con una red de proveedores de servicios calificados y encuentre al profesional adecuado para sus necesidades en el MOM - Mercado final impulsado por IA.',
  canonical: `${appUrl}/es/provider`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/es/provider` },
    { property: 'og:title', content: 'Proveedores de servicios - MOM - Mercado final impulsado por IA' },
    { property: 'og:description', content: 'Conéctese con una red de proveedores de servicios calificados y encuentre al profesional adecuado para sus necesidades en el MOM - Mercado final impulsado por IA.' },
  ]
}));

// French
allPages.push(mappedMetaData({
  title: 'Fournisseurs de services - MOM - Marché final alimenté par IA',
  description: 'Connectez-vous à un réseau de prestataires de services qualifiés et trouvez le professionnel adapté à vos besoins sur le MOM - Marché final alimenté par IA.',
  canonical: `${appUrl}/fr/provider`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/fr/provider` },
    { property: 'og:title', content: 'Fournisseurs de services - MOM - Marché final alimenté par IA' },
    { property: 'og:description', content: 'Connectez-vous à un réseau de prestataires de services qualifiés et trouvez le professionnel adapté à vos besoins sur le MOM - Marché final alimenté par IA.' },
  ]
}));

// Russian
allPages.push(mappedMetaData({
  title: 'Поставщики услуг - MOM - Финальный рынок, управляемый искусственным интеллектом',
  description: 'Подключитесь к сети опытных поставщиков услуг и найдите подходящего специалиста для ваших потребностей на рынке MOM - Финальный рынок, управляемый искусственным интеллектом.',
  canonical: `${appUrl}/ru/provider`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/ru/provider` },
    { property: 'og:title', content: 'Поставщики услуг - MOM - Финальный рынок, управляемый искусственным интеллектом' },
    { property: 'og:description', content: 'Подключитесь к сети опытных поставщиков услуг и найдите подходящего специалиста для ваших потребностей на рынке MOM - Финальный рынок, управляемый искусственным интеллектом.' },
  ]
}));

// Uzbek
allPages.push(mappedMetaData({
  title: 'Xizmat ta\'minotchilar - MOM - Aqlli kuchli bozor',
  description: 'Mahoratli xizmat ta\'minotchilarning tarmoqlariga ulaning va sizning talablaringiz uchun mos mutaxassisni toping MOM - Aqlli kuchli bozorda.',
  canonical: `${appUrl}/uz/provider`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/uz/provider` },
    { property: 'og:title', content: 'Xizmat ta\'minotchilar - MOM - Aqlli kuchli bozor' },
    { property: 'og:description', content: 'Mahoratli xizmat ta\'minotchilarning tarmoqlariga ulaning va sizning talablaringiz uchun mos mutaxassisni toping MOM - Aqlli kuchli bozorda.' },
  ]
}));

// Indonesian
allPages.push(mappedMetaData({
  title: 'Penyedia Layanan - MOM - Marketplace Ultimate AI Powered',
  description: 'Terhubung dengan jaringan penyedia layanan terampil dan temukan profesional yang tepat untuk kebutuhan Anda di MOM - Marketplace Ultimate AI Powered.',
  canonical: `${appUrl}/id/provider`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/id/provider` },
    { property: 'og:title', content: 'Penyedia Layanan - MOM - Marketplace Ultimate AI Powered' },
    { property: 'og:description', content: 'Terhubung dengan jaringan penyedia layanan terampil dan temukan profesional yang tepat untuk kebutuhan Anda di MOM - Marketplace Ultimate AI Powered.' },
  ]
}));

// Urdu
allPages.push(mappedMetaData({
  title: 'سروس فراہم کنندگان - MOM - آخری AI سے محکمہ',
  description: 'مہارت کار سروس فراہم کنندگان کے نیٹ ورک سے جڑیں اور موم - آخری AI سے محکمہ پر اپنی ضروریات کے لیے صحیح پیشہ ور تلاش کریں۔',
  canonical: `${appUrl}/ur/provider`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/ur/provider` },
    { property: 'og:title', content: 'سروس فراہم کنندگان - MOM - آخری AI سے محکمہ' },
    { property: 'og:description', content: 'مہارت کار سروس فراہم کنندگان کے نیٹ ورک سے جڑیں اور موم - آخری AI سے محکمہ پر اپنی ضروریات کے لیے صحیح پیشہ ور تلاش کریں۔' },
  ]
}));

// Portuguese
allPages.push(mappedMetaData({
  title: 'Provedores de serviço - MOM - Marketplace final alimentado por IA',
  description: 'Conecte-se a uma rede de provedores de serviços qualificados e encontre o profissional certo para suas necessidades no MOM - Marketplace final alimentado por IA.',
  canonical: `${appUrl}/pt/provider`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/pt/provider` },
    { property: 'og:title', content: 'Provedores de serviço - MOM - Marketplace final alimentado por IA' },
    { property: 'og:description', content: 'Conecte-se a uma rede de provedores de serviços qualificados e encontre o profissional certo para suas necessidades no MOM - Marketplace final alimentado por IA.' },
  ]
}));

// Bengali
allPages.push(mappedMetaData({
  title: 'সেবা প্রদানকারী - MOM - অত্যন্ত এআই পাওয়ারড মার্কেটপ্লেস',
  description: 'একটি দক্ষ সেবা প্রদানকারী নেটওয়ার্কের সাথে যোগাযোগ করুন এবং আপনার প্রয়োজনীয় জনবলের জন্য সঠিক পেশাদার খুঁজুন - এমওএম - অত্যন্ত এআই পাওয়ারড মার্কেটপ্লেসে।',
  canonical: `${appUrl}/bn/provider`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/bn/provider` },
    { property: 'og:title', content: 'সেবা প্রদানকারী - MOM - অত্যন্ত এআই পাওয়ারড মার্কেটপ্লেস' },
    { property: 'og:description', content: 'একটি দক্ষ সেবা প্রদানকারী নেটওয়ার্কের সাথে যোগাযোগ করুন এবং আপনার প্রয়োজনীয় জনবলের জন্য সঠিক পেশাদার খুঁজুন - এমওএম - অত্যন্ত এআই পাওয়ারড মার্কেটপ্লেসে।' },
  ]
}));

// Chinese
allPages.push(mappedMetaData({
  title: '服务提供商 - MOM - 最终AI动力市场',
  description: '连接到一组经验丰富的服务提供商, 并在MOM - 最终AI动力市场上找到适合您需求的专业人士。',
  canonical: `${appUrl}/zh/provider`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/zh/provider` },
    { property: 'og:title', content: '服务提供商 - MOM - 最终AI动力市场' },
    { property: 'og:description', content: '连接到一组经验丰富的服务提供商, 并在MOM - 最终AI动力市场上找到适合您需求的专业人士。' },
  ]
}));

// Tamil
allPages.push(mappedMetaData({
  title: 'சேவையக வழங்குபவர்கள் - MOM - சர்வதேச ஏக்கம் திறக்கப்பட்ட AI விருப்பப்படி',
  description: 'ஒரு அற்புதமான சேவையக வழங்குபவர்கள் பிணையத்துடன் இணைந்து உங்கள் தேவைகளுக்கு செல்லுநரை கண்டறியவும், MOM - சர்வதேச ஏக்கம் திறக்கப்பட்ட AI விருப்பப்படி மூலம்.',
  canonical: `${appUrl}/ta/provider`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/ta/provider` },
    { property: 'og:title', content: 'சேவையக வழங்குபவர்கள் - MOM - சர்வதேச ஏக்கம் திறக்கப்பட்ட AI விருப்பப்படி' },
    { property: 'og:description', content: 'ஒரு அற்புதமான சேவையக வழங்குபவர்கள் பிணையத்துடன் இணைந்து உங்கள் தேவைகளுக்கு செல்லுநரை கண்டறியவும், MOM - சர்வதேச ஏக்கம் திறக்கப்பட்ட AI விருப்பப்படி மூலம்.' },
  ]
}));

// Italian
allPages.push(mappedMetaData({
  title: 'Fornitori di servizi - MOM - Marketplace finale alimentato da IA',
  description: 'Connettiti a una rete di fornitori di servizi qualificati e trova il professionista giusto per le tue esigenze su MOM - Marketplace finale alimentato da IA.',
  canonical: `${appUrl}/it/provider`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/it/provider` },
    { property: 'og:title', content: 'Fornitori di servizi - MOM - Marketplace finale alimentato da IA' },
    { property: 'og:description', content: 'Connettiti a una rete di fornitori di servizi qualificati e trova il professionista giusto per le tue esigenze su MOM - Marketplace finale alimentato da IA.' },
  ]
}));

// Thai
allPages.push(mappedMetaData({
  title: 'ผู้ให้บริการ - MOM - ตลาดสุดท้ายที่ขับเคลื่อนด้วย AI',
  description: 'เชื่อมต่อกับเครือข่ายของผู้ให้บริการที่มีความเชี่ยวชาญและค้นหาผู้เชี่ยวชาญที่เหมาะสมสำหรับความต้องการของคุณใน MOM - ตลาดสุดท้ายที่ขับเคลื่อนด้วย AI',
  canonical: `${appUrl}/th/provider`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/th/provider` },
    { property: 'og:title', content: 'ผู้ให้บริการ - MOM - ตลาดสุดท้ายที่ขับเคลื่อนด้วย AI' },
    { property: 'og:description', content: 'เชื่อมต่อกับเครือข่ายของผู้ให้บริการที่มีความเชี่ยวชาญและค้นหาผู้เชี่ยวชาญที่เหมาะสมสำหรับความต้องการของคุณใน MOM - ตลาดสุดท้ายที่ขับเคลื่อนด้วย AI' },
  ]
}));

// Malayalam
allPages.push(mappedMetaData({
  title: 'സേവന നിര്‍മാതാക്കള്‍ - MOM - അവസാന AI പ്രവര്‍ത്തിപ്പിച്ച മാര്‍ക്കറ്റ്പ്ലേസ്',
  description: 'നെറ്റ്‌വര്‍ക്കില്‍ നിന്നും കഴിഞ്ഞിരുന്ന് നിങ്ങളുടെ ആവശ്യങ്ങള്‍ക്ക് യോഗമായ സ്ഥാനങ്ങളില്‍ പ്രൊഫഷണല്‍ കണ്ടെത്തുക - MOM - അവസാന AI പ്രവര്‍ത്തിപ്പിച്ച മാര്‍ക്കറ്റ്പ്ലേസ്',
  canonical: `${appUrl}/ml/provider`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/ml/provider` },
    { property: 'og:title', content: 'സേവന നിര്‍മാതാക്കള്‍ - MOM - അവസാന AI പ്രവര്‍ത്തിപ്പിച്ച മാര്‍ക്കറ്റ്പ്ലേസ്' },
    { property: 'og:description', content: 'നെറ്റ്‌വര്‍ക്കില്‍ നിന്നും കഴിഞ്ഞിരുന്ന് നിങ്ങളുടെ ആവശ്യങ്ങള്‍ക്ക് യോഗമായ സ്ഥാനങ്ങളില്‍ പ്രൊഫഷണല്‍ കണ്ടെത്തുക - MOM - അവസാന AI പ്രവര്‍ത്തിപ്പിച്ച മാര്‍ക്കറ്റ്പ്ലേസ്' },
  ]
}));

// Turkish
allPages.push(mappedMetaData({
  title: 'Hizmet Sağlayıcılar - MOM - Ultimate AI destekli Pazar',
  description: 'Deneyimli hizmet sağlayıcılarının ağına bağlanın ve ihtiyaçlarınız için doğru profesyoneli MOM - Ultimate AI destekli Pazar\'da bulun.',
  canonical: `${appUrl}/tr/provider`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/tr/provider` },
    { property: 'og:title', content: 'Hizmet Sağlayıcılar - MOM - Ultimate AI destekli Pazar' },
    { property: 'og:description', content: 'Deneyimli hizmet sağlayıcılarının ağına bağlanın ve ihtiyaçlarınız için doğru profesyoneli MOM - Ultimate AI destekli Pazar\'da bulun.' },
  ]
}));
// Hindi
allPages.push(mappedMetaData({
  title: 'ऑनलाइन शॉप - माँ - अल्टिमेट एआई पावर्ड मार्केटप्लेस',
  description: 'माँ - अल्टिमेट एआई पावर्ड मार्केटप्लेस शॉप पर एक विशाल श्रेणी के उत्पादों को ब्राउज़ और खरीदें।',
  canonical: `${appUrl}/hi/shop`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/hi/shop` },
    { property: 'og:title', content: 'ऑनलाइन शॉप - माँ - अल्टिमेट एआई पावर्ड मार्केटप्लेस' },
    { property: 'og:description', content: 'माँ - अल्टिमेट एआई पावर्ड मार्केटप्लेस शॉप पर एक विशाल श्रेणी के उत्पादों को ब्राउज़ और खरीदें।' },
  ]
}));

// Arabic
allPages.push(mappedMetaData({
  title: 'المتجر الإلكتروني - MOM - السوق النهائي المدعوم بالذكاء الصناعي',
  description: 'تصفح وشراء المنتجات المفضلة لديك من مجموعة واسعة من الفئات في متجر MOM - السوق النهائي المدعوم بالذكاء الصناعي.',
  canonical: `${appUrl}/ar/shop`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/ar/shop` },
    { property: 'og:title', content: 'المتجر الإلكتروني - MOM - السوق النهائي المدعوم بالذكاء الصناعي' },
    { property: 'og:description', content: 'تصفح وشراء المنتجات المفضلة لديك من مجموعة واسعة من الفئات في متجر MOM - السوق النهائي المدعوم بالذكاء الصناعي.' },
  ]
}));

// German
allPages.push(mappedMetaData({
  title: 'Online-Shop - MOM - Ultimativer KI-gesteuerter Marktplatz',
  description: 'Durchsuchen und kaufen Sie Ihre Lieblingsprodukte aus einer breiten Palette von Kategorien im MOM - Ultimativer KI-gesteuerter Marktplatz-Shop.',
  canonical: `${appUrl}/de/shop`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/de/shop` },
    { property: 'og:title', content: 'Online-Shop - MOM - Ultimativer KI-gesteuerter Marktplatz' },
    { property: 'og:description', content: 'Durchsuchen und kaufen Sie Ihre Lieblingsprodukte aus einer breiten Palette von Kategorien im MOM - Ultimativer KI-gesteuerter Marktplatz-Shop.' },
  ]
}));

// Spanish
allPages.push(mappedMetaData({
  title: 'Tienda en línea - MOM - Mercado impulsado por IA definitivo',
  description: 'Explore y compre sus productos favoritos de una amplia gama de categorías en la tienda de MOM - Mercado impulsado por IA definitivo.',
  canonical: `${appUrl}/es/shop`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/es/shop` },
    { property: 'og:title', content: 'Tienda en línea - MOM - Mercado impulsado por IA definitivo' },
    { property: 'og:description', content: 'Explore y compre sus productos favoritos de una amplia gama de categorías en la tienda de MOM - Mercado impulsado por IA definitivo.' },
  ]
}));

// French
allPages.push(mappedMetaData({
  title: 'Boutique en ligne - MOM - Marché alimenté par l\'IA ultime',
  description: 'Parcourez et achetez vos produits préférés parmi une large gamme de catégories sur la boutique MOM - Marché alimenté par l\'IA ultime.',
  canonical: `${appUrl}/fr/shop`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/fr/shop` },
    { property: 'og:title', content: 'Boutique en ligne - MOM - Marché alimenté par l\'IA ultime' },
    { property: 'og:description', content: 'Parcourez et achetez vos produits préférés parmi une large gamme de catégories sur la boutique MOM - Marché alimenté par l\'IA ultime.' },
  ]
}));

// Russian
allPages.push(mappedMetaData({
  title: 'Интернет-магазин - MOM - Последний искусственный интеллект',
  description: 'Просматривайте и покупайте свои любимые товары из широкого ассортимента категорий в магазине MOM - Последний искусственный интеллект.',
  canonical: `${appUrl}/ru/shop`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/ru/shop` },
    { property: 'og:title', content: 'Интернет-магазин - MOM - Последний искусственный интеллект' },
    { property: 'og:description', content: 'Просматривайте и покупайте свои любимые товары из широкого ассортимента категорий в магазине MOM - Последний искусственный интеллект.' },
  ]
}));

// Uzbek
allPages.push(mappedMetaData({
  title: 'Onlayn do\'kon - MOM - So\'nggi aqliy kuchli bozor',
  description: 'MOM - So\'nggi aqliy kuchli bozorda xohlagan mahsulotlaringizni keng qamrovli kategoriyalarda ko\'rib, sotib oling.',
  canonical: `${appUrl}/uz/shop`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/uz/shop` },
    { property: 'og:title', content: 'Onlayn do\'kon - MOM - So\'nggi aqliy kuchli bozor' },
    { property: 'og:description', content: 'MOM - So\'nggi aqliy kuchli bozorda xohlagan mahsulotlaringizni keng qamrovli kategoriyalarda ko\'rib, sotib oling.' },
  ]
}));

// Indonesian
allPages.push(mappedMetaData({
  title: 'Toko Online - MOM - Marketplace Ultimate yang Didukung AI',
  description: 'Jelajahi dan beli produk favorit Anda dari berbagai kategori di toko MOM - Marketplace Ultimate yang Didukung AI.',
  canonical: `${appUrl}/id/shop`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/id/shop` },
    { property: 'og:title', content: 'Toko Online - MOM - Marketplace Ultimate yang Didukung AI' },
    { property: 'og:description', content: 'Jelajahi dan beli produk favorit Anda dari berbagai kategori di toko MOM - Marketplace Ultimate yang Didukung AI.' },
  ]
}));

// Urdu
allPages.push(mappedMetaData({
  title: 'آن لائن شاپ - MOM - آخری AI پاورڈ مارکیٹ پلیس',
  description: 'MOM - آخری AI پاورڈ مارکیٹ پلیس شاپ پر مختلف زمرے میں اپنی پسندیدہ مصنوعات کو تلاش کریں اور خریدیں۔',
  canonical: `${appUrl}/ur/shop`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/ur/shop` },
    { property: 'og:title', content: 'آن لائن شاپ - MOM - آخری AI پاورڈ مارکیٹ پلیس' },
    { property: 'og:description', content: 'MOM - آخری AI پاورڈ مارکیٹ پلیس شاپ پر مختلف زمرے میں اپنی پسندیدہ مصنوعات کو تلاش کریں اور خریدیں۔' },
  ]
}));

// Portuguese
allPages.push(mappedMetaData({
  title: 'Loja Online - MOM - Marketplace Impulsionado por IA Final',
  description: 'Navegue e compre seus produtos favoritos em uma ampla gama de categorias na loja MOM - Marketplace Impulsionado por IA Final.',
  canonical: `${appUrl}/pt/shop`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/pt/shop` },
    { property: 'og:title', content: 'Loja Online - MOM - Marketplace Impulsionado por IA Final' },
    { property: 'og:description', content: 'Navegue e compre seus produtos favoritos em uma ampla gama de categorias na loja MOM - Marketplace Impulsionado por IA Final.' },
  ]
}));

// Bengali
allPages.push(mappedMetaData({
  title: 'অনলাইন দোকান - MOM - চূড়ান্ত AI পাওয়ারড মার্কেটপ্লেস',
  description: 'MOM - চূড়ান্ত AI পাওয়ারড মার্কেটপ্লেস দোকানে বিভিন্ন বিভাগের মধ্যে আপনার প্রিয় পণ্য অনুসন্ধান এবং ক্রয় করুন।',
  canonical: `${appUrl}/bn/shop`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/bn/shop` },
    { property: 'og:title', content: 'অনলাইন দোকান - MOM - চূড়ান্ত AI পাওয়ারড মার্কেটপ্লেস' },
    { property: 'og:description', content: 'MOM - চূড়ান্ত AI পাওয়ারড মার্কেটপ্লেস দোকানে বিভিন্ন বিভাগের মধ্যে আপনার প্রিয় পণ্য অনুসন্ধান এবং ক্রয় করুন।' },
  ]
}));

// Chinese
allPages.push(mappedMetaData({
  title: '在线商店 - MOM - 终极人工智能驱动的市场',
  description: '在MOM - 终极人工智能驱动的市场商店中浏览并购买您喜爱的各种类别产品。',
  canonical: `${appUrl}/zh/shop`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/zh/shop` },
    { property: 'og:title', content: '在线商店 - MOM - 终极人工智能驱动的市场' },
    { property: 'og:description', content: '在MOM - 终极人工智能驱动的市场商店中浏览并购买您喜爱的各种类别产品。' },
  ]
}));

// Tamil
allPages.push(mappedMetaData({
  title: 'ஆன்லைன் ஷாப் - MOM - முடிவு ஐயம் செயல்படுத்தப்பட்ட சந்தை',
  description: 'தங்கள் பிடித்த பொருட்களை MOM - முடிவு ஐயம் செயல்படுத்தப்பட்ட சந்தையில் வெளியிடும் மற்றும் வாங்கும்.',
  canonical: `${appUrl}/ta/shop`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/ta/shop` },
    { property: 'og:title', content: 'ஆன்லைன் ஷாப் - MOM - முடிவு ஐயம் செயல்படுத்தப்பட்ட சந்தை' },
    { property: 'og:description', content: 'தங்கள் பிடித்த பொருட்களை MOM - முடிவு ஐயம் செயல்படுத்தப்பட்ட சந்தையில் வெளியிடும் மற்றும் வாங்கும்.' },
  ]
}));

// Italian
allPages.push(mappedMetaData({
  title: 'Negozio online - MOM - Marketplace alimentato da IA finale',
  description: 'Esplora e acquista i tuoi prodotti preferiti da una vasta gamma di categorie nel negozio MOM - Marketplace alimentato da IA finale.',
  canonical: `${appUrl}/it/shop`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/it/shop` },
    { property: 'og:title', content: 'Negozio online - MOM - Marketplace alimentato da IA finale' },
    { property: 'og:description', content: 'Esplora e acquista i tuoi prodotti preferiti da una vasta gamma di categorie nel negozio MOM - Marketplace alimentato da IA finale.' },
  ]
}));

// Thai
allPages.push(mappedMetaData({
  title: 'ร้านค้าออนไลน์ - MOM - ตลาดดิจิตอลพลังงาน AI สุดท้าย',
  description: 'เรียกดูและซื้อสินค้าที่คุณชื่นชอบจากหมวดหมู่หลากหลายในร้านค้า MOM - ตลาดดิจิตอลพลังงาน AI สุดท้าย',
  canonical: `${appUrl}/th/shop`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/th/shop` },
    { property: 'og:title', content: 'ร้านค้าออนไลน์ - MOM - ตลาดดิจิตอลพลังงาน AI สุดท้าย' },
    { property: 'og:description', content: 'เรียกดูและซื้อสินค้าที่คุณชื่นชอบจากหมวดหมู่หลากหลายในร้านค้า MOM - ตลาดดิจิตอลพลังงาน AI สุดท้าย' },
  ]
}));

// Malayalam
allPages.push(mappedMetaData({
  title: 'ഓൺ‌ലൈൻ ഷോപ്പ് - MOM - ഒന്നാമത് എ.ഐ. പവർഡ് മാർക്കറ്റ്പ്ലേസ്',
  description: 'MOM - ഒന്നാമത് എ.ഐ. പവർഡ് മാർക്കറ്റ്പ്ലേസ് ഷോപ്പിൽ വിവിധ വിഭാഗങ്ങളിൽ നിന്നുള്ള നിങ്ങളുടെ പ്രിയ ഉത്പന്നങ്ങൾ അന്വേഷിക്കുക കൊണ്ട് വാങ്ങുക.',
  canonical: `${appUrl}/ml/shop`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/ml/shop` },
    { property: 'og:title', content: 'ഓൺ‌ലൈൻ ഷോപ്പ് - MOM - ഒന്നാമത് എ.ഐ. പവർഡ് മാർക്കറ്റ്പ്ലേസ്' },
    { property: 'og:description', content: 'MOM - ഒന്നാമത് എ.ഐ. പവർഡ് മാർക്കറ്റ്പ്ലേസ് ഷോപ്പിൽ വിവിധ വിഭാഗങ്ങളിൽ നിന്നുള്ള നിങ്ങളുടെ പ്രിയ ഉത്പന്നങ്ങൾ അന്വേഷിക്കുക കൊണ്ട് വാങ്ങുക.' },
  ]
}));

// Turkish
allPages.push(mappedMetaData({
  title: 'Online Mağaza - MOM - Nihai AI Güçlü Pazar Yeri',
  description: 'MOM - Nihai AI Güçlü Pazar Yeri mağazasında çeşitli kategorilerden favori ürünlerinizi keşfedin ve satın alın.',
  canonical: `${appUrl}/tr/shop`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/tr/shop` },
    { property: 'og:title', content: 'Online Mağaza - MOM - Nihai AI Güçlü Pazar Yeri' },
    { property: 'og:description', content: 'MOM - Nihai AI Güçlü Pazar Yeri mağazasında çeşitli kategorilerden favori ürünlerinizi keşfedin ve satın alın.' },
  ]
}));
// Hindi
allPages.push(mappedMetaData({
  title: 'MOM | एआई | आदेश प्रबंधन | डीएमएस | बुकिंग प्रबंधन ',
  description: 'MOM - अंतिम एआई सशक्त बाजार में आपका स्वागत है, एक एआई-सशक्त प्लेटफ़ॉर्म जो आपको एआई सामग्री, डिजिटल स्टोरफ़्रंट्स, बुकिंग प्रबंधन और अपने व्यापार को विस्तारित करने की संभावना प्रदान करता है।',
  canonical: `${appUrl}/hi/main`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/hi/main` },
    { property: 'og:title', content: 'MOM | एआई | आदेश प्रबंधन | डीएमएस | बुकिंग प्रबंधन' },
    { property: 'og:description', content: 'MOM - अंतिम एआई सशक्त बाजार में आपका स्वागत है, एक एआई-सशक्त प्लेटफ़ॉर्म जो आपको एआई सामग्री, डिजिटल स्टोरफ़्रंट्स, बुकिंग प्रबंधन और अपने व्यापार को विस्तारित करने की संभावना प्रदान करता है।' },
  ]
}));

// Arabic
allPages.push(mappedMetaData({
  title: 'MOM | الذكاء الاصطناعي | إدارة الطلبات | نظام إدارة التوزيع | إدارة الحجوزات',
  description: 'مرحبًا بك في MOM - السوق النهائي المدعوم بالذكاء الاصطناعي، منصة تمكنك من إنشاء محتوى ذكاء اصطناعي ومتاجر رقمية وإدارة الحجوزات وتوسيع عملك.',
  canonical: `${appUrl}/ar/main`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/ar/main` },
    { property: 'og:title', content: 'MOM | الذكاء الاصطناعي | إدارة الطلبات | نظام إدارة التوزيع | إدارة الحجوزات' },
    { property: 'og:description', content: 'مرحبًا بك في MOM - السوق النهائي المدعوم بالذكاء الاصطناعي، منصة تمكنك من إنشاء محتوى ذكاء اصطناعي ومتاجر رقمية وإدارة الحجوزات وتوسيع عملك.' },
  ]
}));

// German
allPages.push(mappedMetaData({
  title: 'MOM | KI | Auftragsmanagement | DMS | Buchungsmanagement',
  description: 'Willkommen bei MOM - dem ultimativen KI-gestützten Marktplatz, einer KI-gestützten Plattform, die es Ihnen ermöglicht, KI-Inhalte, digitale Schaufenster, Buchungsmanagement zu erstellen und Ihr Geschäft auszubauen.',
  canonical: `${appUrl}/de/main`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/de/main` },
    { property: 'og:title', content: 'MOM | KI | Auftragsmanagement | DMS | Buchungsmanagement' },
    { property: 'og:description', content: 'Willkommen bei MOM - dem ultimativen KI-gestützten Marktplatz, einer KI-gestützten Plattform, die es Ihnen ermöglicht, KI-Inhalte, digitale Schaufenster, Buchungsmanagement zu erstellen und Ihr Geschäft auszubauen.' },
  ]
}));

// Spanish
allPages.push(mappedMetaData({
  title: 'MOM | IA | Gestión de pedidos | DMS | Gestión de reservas',
  description: 'Bienvenido a MOM - el último mercado impulsado por IA, una plataforma impulsada por IA que le permite crear contenido de IA, escaparates digitales, gestión de reservas y expandir su negocio.',
  canonical: `${appUrl}/es/main`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/es/main` },
    { property: 'og:title', content: 'MOM | IA | Gestión de pedidos | DMS | Gestión de reservas' },
    { property: 'og:description', content: 'Bienvenido a MOM - el último mercado impulsado por IA, una plataforma impulsada por IA que le permite crear contenido de IA, escaparates digitales, gestión de reservas y expandir su negocio.' },
  ]
}));

// French
allPages.push(mappedMetaData({
  title: 'MOM | IA | Gestion des commandes | DMS | Gestion des réservations',
  description: 'Bienvenue sur MOM - le marché ultime alimenté par l\'IA, une plateforme alimentée par l\'IA qui vous permet de créer du contenu IA, des vitrines numériques, une gestion des réservations et d\'élargir votre entreprise.',
  canonical: `${appUrl}/fr/main`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/fr/main` },
    { property: 'og:title', content: 'MOM | IA | Gestion des commandes | DMS | Gestion des réservations' },
    { property: 'og:description', content: 'Bienvenue sur MOM - le marché ultime alimenté par l\'IA, une plateforme alimentée par l\'IA qui vous permet de créer du contenu IA, des vitrines numériques, une gestion des réservations et d\'élargir votre entreprise.' },
  ]
}));

// Russian
allPages.push(mappedMetaData({
  title: 'MOM | ИИ | Управление заказами | DMS | Управление бронированием',
  description: 'Добро пожаловать в MOM - последний рынок, управляемый ИИ, платформу, питающуюся ИИ, которая позволяет вам создавать контент ИИ, цифровые витрины, управление бронированием и расширять ваш бизнес.',
  canonical: `${appUrl}/ru/main`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/ru/main` },
    { property: 'og:title', content: 'MOM | ИИ | Управление заказами | DMS | Управление бронированием' },
    { property: 'og:description', content: 'Добро пожаловать в MOM - последний рынок, управляемый ИИ, платформу, питающуюся ИИ, которая позволяет вам создавать контент ИИ, цифровые витрины, управление бронированием и расширять ваш бизнес.' },
  ]
}));

// Uzbek
allPages.push(mappedMetaData({
  title: 'MOM | AI | Buyurtma boshqarish | DMS | Bandlamoqni boshqarish',
  description: 'MOM-ga xush kelibsiz - ajoyib AI kuchli bozor, sizga AI-ta\'minlangan platformada AI tarkibini yaratish, raqamli do\'konlar, bandlamoqni boshqarish va biznesingizni kengaytirish imkoniyatini beruvchi platforma.',
  canonical: `${appUrl}/uz/main`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/uz/main` },
    { property: 'og:title', content: 'MOM | AI | Buyurtma boshqarish | DMS | Bandlamoqni boshqarish' },
    { property: 'og:description', content: 'MOM-ga xush kelibsiz - ajoyib AI kuchli bozor, sizga AI-ta\'minlangan platformada AI tarkibini yaratish, raqamli do\'konlar, bandlamoqni boshqarish va biznesingizni kengaytirish imkoniyatini beruvchi platforma.' },
  ]
}));

// Indonesian
allPages.push(mappedMetaData({
  title: 'MOM | AI | Manajemen Pesanan | DMS | Manajemen Pemesanan',
  description: 'Selamat datang di MOM - Marketplace tercanggih yang didukung AI, platform yang didukung AI yang memungkinkan Anda membuat konten AI, toko digital, manajemen pemesanan, dan memperluas bisnis Anda.',
  canonical: `${appUrl}/id/main`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/id/main` },
    { property: 'og:title', content: 'MOM | AI | Manajemen Pesanan | DMS | Manajemen Pemesanan' },
    { property: 'og:description', content: 'Selamat datang di MOM - Marketplace tercanggih yang didukung AI, platform yang didukung AI yang memungkinkan Anda membuat konten AI, toko digital, manajemen pemesanan, dan memperluas bisnis Anda.' },
  ]
}));

// Urdu
allPages.push(mappedMetaData({
  title: 'MOM | اے آئی | آرڈر کی انتظام | ڈی ایم ایس | بکنگ کی انتظام',
  description: 'MOM میں خوش آمدید - آخری اے آئی پاورڈ مارکیٹ پلیس، ایک اے آئی پاورڈ پلیٹ فارم جو آپ کو اے آئی کنٹینٹ، ڈیجیٹل دکانیں، بکنگ کا انتظام بنانے اور آپ کے کاروبار کو بڑھانے کی امکان فراہم کرتا ہے۔',
  canonical: `${appUrl}/ur/main`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/ur/main` },
    { property: 'og:title', content: 'MOM | اے آئی | آرڈر کی انتظام | ڈی ایم ایس | بکنگ کی انتظام' },
    { property: 'og:description', content: 'MOM میں خوش آمدید - آخری اے آئی پاورڈ مارکیٹ پلیس، ایک اے آئی پاورڈ پلیٹ فارم جو آپ کو اے آئی کنٹینٹ، ڈیجیٹل دکانیں، بکنگ کا انتظام بنانے اور آپ کے کاروبار کو بڑھانے کی امکان فراہم کرتا ہے۔' },
  ]
}));

// Portuguese
allPages.push(mappedMetaData({
  title: 'MOM | IA | Gerenciamento de Pedidos | DMS | Gerenciamento de Reservas',
  description: 'Bem-vindo ao MOM - Marketplace final alimentado por IA, uma plataforma alimentada por IA que permite que você crie conteúdo de IA, vitrines digitais, gerenciamento de reservas e expanda seu negócio.',
  canonical: `${appUrl}/pt/main`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/pt/main` },
    { property: 'og:title', content: 'MOM | IA | Gerenciamento de Pedidos | DMS | Gerenciamento de Reservas' },
    { property: 'og:description', content: 'Bem-vindo ao MOM - Marketplace final alimentado por IA, uma plataforma alimentada por IA que permite que você crie conteúdo de IA, vitrines digitais, gerenciamento de reservas e expanda seu negócio.' },
  ]
}));

// Bengali
allPages.push(mappedMetaData({
  title: 'MOM | এআই | অর্ডার পরিচালনা | ডিএমএস | বুকিং পরিচালনা',
  description: 'MOM-এ আপনাকে স্বাগতম - অত্যাধুনিক AI-সশক্ত মার্কেটপ্লেস, একটি AI-সশক্ত প্ল্যাটফর্ম যা আপনাকে AI কন্টেন্ট, ডিজিটাল দোকান, বুকিং পরিচালনা তৈরি করতে এবং আপনার ব্যবসা প্রসারিত করতে সক্ষম করে।',
  canonical: `${appUrl}/bn/main`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/bn/main` },
    { property: 'og:title', content: 'MOM | এআই | অর্ডার পরিচালনা | ডিএমএস | বুকিং পরিচালনা' },
    { property: 'og:description', content: 'MOM-এ আপনাকে স্বাগতম - অত্যাধুনিক AI-সশক্ত মার্কেটপ্লেস, একটি AI-সশক্ত প্ল্যাটফর্ম যা আপনাকে AI কন্টেন্ট, ডিজিটাল দোকান, বুকিং পরিচালনা তৈরি করতে এবং আপনার ব্যবসা প্রসারিত করতে সক্ষম করে।' },
  ]
}));

// Chinese
allPages.push(mappedMetaData({
  title: 'MOM | AI | 订单管理 | DMS | 预订管理',
  description: '欢迎来到MOM - 终极AI动力市场, 一款由AI驱动的平台, 让您可以创建AI内容、数字商店、预订管理, 并拓展您的业务。',
  canonical: `${appUrl}/zh/main`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/zh/main` },
    { property: 'og:title', content: 'MOM | AI | 订单管理 | DMS | 预订管理' },
    { property: 'og:description', content: '欢迎来到MOM - 终极AI动力市场, 一款由AI驱动的平台, 让您可以创建AI内容、数字商店、预订管理, 并拓展您的业务。' },
  ]
}));

// Tamil
allPages.push(mappedMetaData({
  title: 'MOM | அறிவியல் | ஆர்டர் மேலாண்மை | டிஎம்எஸ் | புகைப்பட மேலாண்மை',
  description: 'MOMக்கு வரவேற்கின்றேன் - அளவிலான AI சக்தி மார்க்கெட்டை, உங்களுக்கு AI உள்ளடக்கத்தை, எண்ணிக்கைகள் மற்றும் வளர்ச்சியை உருவாக்க அனுமதிக்கும் AI-மூலம் சாதனை.',
  canonical: `${appUrl}/ta/main`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/ta/main` },
    { property: 'og:title', content: 'MOM | அறிவியல் | ஆர்டர் மேலாண்மை | டிஎம்எஸ் | புகைப்பட மேலாண்மை' },
    { property: 'og:description', content: 'MOMக்கு வரவேற்கின்றேன் - அளவிலான AI சக்தி மார்க்கெட்டை, உங்களுக்கு AI உள்ளடக்கத்தை, எண்ணிக்கைகள் மற்றும் வளர்ச்சியை உருவாக்க அனுமதிக்கும் AI-மூலம் சாதனை.' },
  ]
}));

// Italian
allPages.push(mappedMetaData({
  title: 'MOM | AI | Gestione Ordini | DMS | Gestione Prenotazioni',
  description: 'Benvenuti su MOM - Marketplace finale alimentato da IA, una piattaforma alimentata da IA che ti consente di creare contenuti AI, vetrine digitali, gestione prenotazioni e espandere la tua attività.',
  canonical: `${appUrl}/it/main`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/it/main` },
    { property: 'og:title', content: 'MOM | AI | Gestione Ordini | DMS | Gestione Prenotazioni' },
    { property: 'og:description', content: 'Benvenuti su MOM - Marketplace finale alimentato da IA, una piattaforma alimentata da IA che ti consente di creare contenuti AI, vetrine digitali, gestione prenotazioni e espandere la tua attività.' },
  ]
}));

// Thai
allPages.push(mappedMetaData({
  title: 'MOM | AI | การจัดการคำสั่งซื้อ | DMS | การจัดการการจอง',
  description: 'ยินดีต้อนรับสู่ MOM - ตลาดอัจฉริยะสุดท้ายที่ขับเคลื่อนด้วย AI, แพลตฟอร์มที่ขับเคลื่อนด้วย AI ซึ่งช่วยให้คุณสามารถสร้างเนื้อหา AI, ร้านค้าดิจิทัล, การจัดการการจองและขยายธุรกิจของคุณได้',
  canonical: `${appUrl}/th/main`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/th/main` },
    { property: 'og:title', content: 'MOM | AI | การจัดการคำสั่งซื้อ | DMS | การจัดการการจอง' },
    { property: 'og:description', content: 'ยินดีต้อนรับสู่ MOM - ตลาดอัจฉริยะสุดท้ายที่ขับเคลื่อนด้วย AI, แพลตฟอร์มที่ขับเคลื่อนด้วย AI ซึ่งช่วยให้คุณสามารถสร้างเนื้อหา AI, ร้านค้าดิจิทัล, การจัดการการจองและขยายธุรกิจของคุณได้' },
  ]
}));

// Malayalam
allPages.push(mappedMetaData({
  title: 'MOM | AI | ഓർഡർ മാനേജ്മെന്റ് | ഡിഎംഎസ് | ബുക്കിംഗ് മാനേജ്മെന്റ്',
  description: 'MOM - അന്തിമ എയ്‌ഐ പവർഡ് മാർക്കറ്റ്‌പ്ലേസിൽ സ്വാഗതം, ഒരു എയ്‌ഐ-പവർഡ് പ്ലാറ്റ്‌ഫോം ആണ് മാനേജ് ആര്‍ഡരുകള്‍, ഡിജിറ്റൽ സ്‌റ്റോര്‍ഫ്രണ്ടുകള്‍, ബുക്കിംഗ് മാനേജ്‌മെന്റ് ഉണ്ടാക്കാനും നിങ്ങളുടെ വ്യാപാരം വിസ്‌തരിപ്പിക്കാനും സാധിക്കുന്ന ഒരു എയ്‌ഐ-പവർഡ് പ്ലാറ്റ്‌ഫോം.',
  canonical: `${appUrl}/ml/main`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/ml/main` },
    { property: 'og:title', content: 'MOM | AI | ഓർഡർ മാനേജ്മെന്റ് | ഡിഎംഎസ് | ബുക്കിംഗ് മാനേജ്മെന്റ്' },
    { property: 'og:description', content: 'MOM - അന്തിമ എയ്‌ഐ പവർഡ് മാർക്കറ്റ്‌പ്ലേസിൽ സ്വാഗതം, ഒരു എയ്‌ഐ-പവർഡ് പ്ലാറ്റ്‌ഫോം ആണ് മാനേജ് ആര്‍ഡരുകള്‍, ഡിജിറ്റൽ സ്‌റ്റോര്‍ഫ്രണ്ടുകള്‍, ബുക്കിംഗ് മാനേജ്‌മെന്റ് ഉണ്ടാക്കാനും നിങ്ങളുടെ വ്യാപാരം വിസ്‌തരിപ്പിക്കാനും സാധിക്കുന്ന ഒരു എയ്‌ഐ-പവർഡ് പ്ലാറ്റ്‌ഫോം.' },
  ]
}));

// Turkish
allPages.push(mappedMetaData({
  title: 'MOM | AI | Sipariş Yönetimi | DMS | Rezervasyon Yönetimi',
  description: 'MOM\'a hoş geldiniz - AI destekli en son pazar yeri, AI destekli bir platform olan MOM size AI içerikleri, dijital mağazalar, rezervasyon yönetimi oluşturmanızı ve işinizi genişletmenizi sağlar.',
  canonical: `${appUrl}/tr/main`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/tr/main` },
    { property: 'og:title', content: 'MOM | AI | Sipariş Yönetimi | DMS | Rezervasyon Yönetimi' },
    { property: 'og:description', content: 'MOM\'a hoş geldiniz - AI destekli en son pazar yeri, AI destekli bir platform olan MOM size AI içerikleri, dijital mağazalar, rezervasyon yönetimi oluşturmanızı ve işinizi genişletmenizi sağlar.' },
  ]
}));

// Hindi
allPages.push(mappedMetaData({
  title: 'उपयोगकर्ता खाता - MOM - अंतिम एआई सहायक बाजार',
  description: 'अपने उपयोगकर्ता खाते में पहुंचें और MOM - अंतिम एआई सहायक बाजार के अनुभव को अनुकूलित करें। अपने आदेश, प्रोफाइल और सेटिंग्स को एक ही जगह पर प्रबंधित करें।',
  canonical: `${appUrl}/hi/user`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/hi/user` },
    { property: 'og:title', content: 'उपयोगकर्ता खाता - MOM - अंतिम एआई सहायक बाजार' },
    { property: 'og:description', content: 'अपने उपयोगकर्ता खाते में पहुंचें और MOM - अंतिम एआई सहायक बाजार के अनुभव को अनुकूलित करें। अपने आदेश, प्रोफाइल और सेटिंग्स को एक ही जगह पर प्रबंधित करें।' },
  ]
}));

// Arabic
allPages.push(mappedMetaData({
  title: 'حساب المستخدم - MOM - السوق النهائي المدعوم بالذكاء الصناعي',
  description: 'قم بالوصول إلى حساب المستخدم الخاص بك واشخص تجربتك في MOM - السوق النهائي المدعوم بالذكاء الصناعي. قم بإدارة طلباتك وملفك الشخصي وإعداداتك في مكان واحد.',
  canonical: `${appUrl}/ar/user`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/ar/user` },
    { property: 'og:title', content: 'حساب المستخدم - MOM - السوق النهائي المدعوم بالذكاء الصناعي' },
    { property: 'og:description', content: 'قم بالوصول إلى حساب المستخدم الخاص بك واشخص تجربتك في MOM - السوق النهائي المدعوم بالذكاء الصناعي. قم بإدارة طلباتك وملفك الشخصي وإعداداتك في مكان واحد.' },
  ]
}));

// German
allPages.push(mappedMetaData({
  title: 'Benutzerkonto - MOM - Ultimativer KI-unterstützter Marktplatz',
  description: 'Greifen Sie auf Ihr Benutzerkonto zu und personalisieren Sie Ihre Erfahrung mit MOM - dem ultimativen KI-unterstützten Marktplatz. Verwalten Sie Ihre Bestellungen, Ihr Profil und Ihre Einstellungen an einem Ort.',
  canonical: `${appUrl}/de/user`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/de/user` },
    { property: 'og:title', content: 'Benutzerkonto - MOM - Ultimativer KI-unterstützter Marktplatz' },
    { property: 'og:description', content: 'Greifen Sie auf Ihr Benutzerkonto zu und personalisieren Sie Ihre Erfahrung mit MOM - dem ultimativen KI-unterstützten Marktplatz. Verwalten Sie Ihre Bestellungen, Ihr Profil und Ihre Einstellungen an einem Ort.' },
  ]
}));

// Spanish
allPages.push(mappedMetaData({
  title: 'Cuenta de usuario - MOM - Mercado definitivo impulsado por IA',
  description: 'Acceda a su cuenta de usuario y personalice su experiencia en MOM - el mercado definitivo impulsado por IA. Administre sus pedidos, perfil y configuraciones en un solo lugar.',
  canonical: `${appUrl}/es/user`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/es/user` },
    { property: 'og:title', content: 'Cuenta de usuario - MOM - Mercado definitivo impulsado por IA' },
    { property: 'og:description', content: 'Acceda a su cuenta de usuario y personalice su experiencia en MOM - el mercado definitivo impulsado por IA. Administre sus pedidos, perfil y configuraciones en un solo lugar.' },
  ]
}));

// French
allPages.push(mappedMetaData({
  title: 'Compte utilisateur - MOM - Marché ultime alimenté par l\'IA',
  description: 'Accédez à votre compte utilisateur et personnalisez votre expérience sur MOM - le marché ultime alimenté par l\'IA. Gérez vos commandes, votre profil et vos paramètres en un seul endroit.',
  canonical: `${appUrl}/fr/user`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/fr/user` },
    { property: 'og:title', content: 'Compte utilisateur - MOM - Marché ultime alimenté par l\'IA' },
    { property: 'og:description', content: 'Accédez à votre compte utilisateur et personnalisez votre expérience sur MOM - le marché ultime alimenté par l\'IA. Gérez vos commandes, votre profil et vos paramètres en un seul endroit.' },
  ]
}));

// Russian
allPages.push(mappedMetaData({
  title: 'Пользовательский аккаунт - MOM - Последний AI Marketplace',
  description: 'Доступ к вашей учетной записи пользователя и настройте свой опыт с MOM - последним AI Marketplace. Управляйте своими заказами, профилем и настройками в одном месте.',
  canonical: `${appUrl}/ru/user`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/ru/user` },
    { property: 'og:title', content: 'Пользовательский аккаунт - MOM - Последний AI Marketplace' },
    { property: 'og:description', content: 'Доступ к вашей учетной записи пользователя и настройте свой опыт с MOM - последним AI Marketplace. Управляйте своими заказами, профилем и настройками в одном месте.' },
  ]
}));

// Uzbek
allPages.push(mappedMetaData({
  title: 'Foydalanuvchi hisobi - MOM - So\'nggi AI Marketplace',
  description: 'Foydalanuvchi hisobingizga kirib, MOM - so\'nggi AI Marketplace tajribangizni moslashtiring. Buyurtmalaringizni, profilni va sozlamalaringizni bitta joyda boshqarib turin.',
  canonical: `${appUrl}/uz/user`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/uz/user` },
    { property: 'og:title', content: 'Foydalanuvchi hisobi - MOM - So\'nggi AI Marketplace' },
    { property: 'og:description', content: 'Foydalanuvchi hisobingizga kirib, MOM - so\'nggi AI Marketplace tajribangizni moslashtiring. Buyurtmalaringizni, profilni va sozlamalaringizni bitta joyda boshqarib turin.' },
  ]
}));

// Indonesian
allPages.push(mappedMetaData({
  title: 'Akun Pengguna - MOM - Marketplace AI Terakhir',
  description: 'Akses akun pengguna Anda dan sesuaikan pengalaman Anda dengan MOM - Marketplace AI Terakhir. Kelola pesanan, profil, dan pengaturan Anda di satu tempat.',
  canonical: `${appUrl}/id/user`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/id/user` },
    { property: 'og:title', content: 'Akun Pengguna - MOM - Marketplace AI Terakhir' },
    { property: 'og:description', content: 'Akses akun pengguna Anda dan sesuaikan pengalaman Anda dengan MOM - Marketplace AI Terakhir. Kelola pesanan, profil, dan pengaturan Anda di satu tempat.' },
  ]
}));

// Urdu
allPages.push(mappedMetaData({
  title: 'صارف کا اکاؤنٹ - MOM - آخری AI مارکیٹ پلیس',
  description: 'اپنے صارف کے اکاؤنٹ تک رسائی حاصل کریں اور MOM - آخری AI مارکیٹ پلیس میں اپنی تجربہ کو شخصی بنائیں۔ اپنے آرڈرز، پروفائل، اور ترتیبات کو ایک ہی جگہ میں منظم کریں۔',
  canonical: `${appUrl}/ur/user`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/ur/user` },
    { property: 'og:title', content: 'صارف کا اکاؤنٹ - MOM - آخری AI مارکیٹ پلیس' },
    { property: 'og:description', content: 'اپنے صارف کے اکاؤنٹ تک رسائی حاصل کریں اور MOM - آخری AI مارکیٹ پلیس میں اپنی تجربہ کو شخصی بنائیں۔ اپنے آرڈرز، پروفائل، اور ترتیبات کو ایک ہی جگہ میں منظم کریں۔' },
  ]
}));

// Portuguese
allPages.push(mappedMetaData({
  title: 'Conta do Usuário - MOM - Último Marketplace de IA',
  description: 'Acesse sua conta de usuário e personalize sua experiência com o MOM - Último Marketplace de IA. Gerencie seus pedidos, perfil e configurações em um só lugar.',
  canonical: `${appUrl}/pt/user`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/pt/user` },
    { property: 'og:title', content: 'Conta do Usuário - MOM - Último Marketplace de IA' },
    { property: 'og:description', content: 'Acesse sua conta de usuário e personalize sua experiência com o MOM - Último Marketplace de IA. Gerencie seus pedidos, perfil e configurações em um só lugar.' },
  ]
}));

// Bengali
allPages.push(mappedMetaData({
  title: 'ব্যবহারকারীর অ্যাকাউন্ট - MOM - শেষ AI মার্কেটপ্লেস',
  description: 'আপনার ব্যবহারকারী অ্যাকাউন্টে অ্যাক্সেস করুন এবং মম - শেষ AI মার্কেটপ্লেস এ আপনার অভিজ্ঞতা কাস্টমাইজ করুন। আপনার অর্ডার, প্রোফাইল এবং সেটিংস সমস্ত একই জায়গায় পরিচালনা করুন।',
  canonical: `${appUrl}/bn/user`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/bn/user` },
    { property: 'og:title', content: 'ব্যবহারকারীর অ্যাকাউন্ট - MOM - শেষ AI মার্কেটপ্লেস' },
    { property: 'og:description', content: 'আপনার ব্যবহারকারী অ্যাকাউন্টে অ্যাক্সেস করুন এবং মম - শেষ AI মার্কেটপ্লেস এ আপনার অভিজ্ঞতা কাস্টমাইজ করুন। আপনার অর্ডার, প্রোফাইল এবং সেটিংস সমস্ত একই জায়গায় পরিচালনা করুন।' },
  ]
}));

// Chinese
allPages.push(mappedMetaData({
  title: '用户帐户 - MOM - 最终AI市场',
  description: '访问您的用户帐户并个性化您在MOM - 最终AI市场的体验。 在一个地方管理您的订单、个人资料和设置。',
  canonical: `${appUrl}/zh/user`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/zh/user` },
    { property: 'og:title', content: '用户帐户 - MOM - 最终AI市场' },
    { property: 'og:description', content: '访问您的用户帐户并个性化您在MOM - 最终AI市场的体验。 在一个地方管理您的订单、个人资料和设置。' },
  ]
}));

// Tamil
allPages.push(mappedMetaData({
  title: 'பயனர் கணக்கு - MOM - கடைசி ஏஐ மார்க்கெட்',
  description: 'உங்கள் பயனர் கணக்குக்கு அணுகவும் மற்றும் உங்கள் MOM - கடைசி ஏஐ மார்க்கெட் அனுபவத்தை கருத்திக்கொள்ளவும். ஒரே இடத்தில் உங்கள் ஆர்டர்களை, சுயவிவரத்தை மற்றும் அமைப்புகளை நிர்வகிக்கவும்.',
  canonical: `${appUrl}/ta/user`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/ta/user` },
    { property: 'og:title', content: 'பயனர் கணக்கு - MOM - கடைசி ஏஐ மார்க்கெட்' },
    { property: 'og:description', content: 'உங்கள் பயனர் கணக்குக்கு அணுகவும் மற்றும் உங்கள் MOM - கடைசி ஏஐ மார்க்கெட் அனுபவத்தை கருத்திக்கொள்ளவும். ஒரே இடத்தில் உங்கள் ஆர்டர்களை, சுயவிவரத்தை மற்றும் அமைப்புகளை நிர்வகிக்கவும்.' },
  ]
}));

// Italian
allPages.push(mappedMetaData({
  title: 'Account utente - MOM - Marketplace AI finale',
  description: 'Accedi al tuo account utente e personalizza la tua esperienza con MOM - il Marketplace AI finale. Gestisci i tuoi ordini, il profilo e le impostazioni in un unico luogo.',
  canonical: `${appUrl}/it/user`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/it/user` },
    { property: 'og:title', content: 'Account utente - MOM - Marketplace AI finale' },
    { property: 'og:description', content: 'Accedi al tuo account utente e personalizza la tua esperienza con MOM - il Marketplace AI finale. Gestisci i tuoi ordini, il profilo e le impostazioni in un unico luogo.' },
  ]
}));

// Thai
allPages.push(mappedMetaData({
  title: 'บัญชีผู้ใช้ - MOM - ตลาด AI สุดท้าย',
  description: 'เข้าถึงบัญชีผู้ใช้ของคุณและปรับแต่งประสบการณ์กับ MOM - ตลาด AI สุดท้าย จัดการคำสั่งซื้อโปรไฟล์และการตั้งค่าของคุณได้ที่เดียว',
  canonical: `${appUrl}/th/user`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/th/user` },
    { property: 'og:title', content: 'บัญชีผู้ใช้ - MOM - ตลาด AI สุดท้าย' },
    { property: 'og:description', content: 'เข้าถึงบัญชีผู้ใช้ของคุณและปรับแต่งประสบการณ์กับ MOM - ตลาด AI สุดท้าย จัดการคำสั่งซื้อโปรไฟล์และการตั้งค่าของคุณได้ที่เดียว' },
  ]
}));

// Malayalam
allPages.push(mappedMetaData({
  title: 'ഉപയോക്തൃ അക്കൗണ്ട് - MOM - അവസാന എയ്‌ഐ മാർക്കറ്റ്',
  description: 'നിങ്ങളുടെ ഉപയോക്തൃ അക്കൗണ്ടിലേക്ക് പ്രവേശിക്കുക, MOM - അവസാന എയ്‌ഐ മാർക്കറ്റിലെ നിങ്ങളുടെ അനുഭവം ക്രമീകരിക്കുക. ഒരു താഴെ നിന്നും നിങ്ങളുടെ ഓർഡർകൾ, പ്രൊഫൈൽ മറ്റും സെറ്റിങ്കൾ അടങ്ങുക.',
  canonical: `${appUrl}/ml/user`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/ml/user` },
    { property: 'og:title', content: 'ഉപയോക്തൃ അക്കൗണ്ട് - MOM - അവസാന എയ്‌ഐ മാർക്കറ്റ്' },
    { property: 'og:description', content: 'നിങ്ങളുടെ ഉപയോക്തൃ അക്കൗണ്ടിലേക്ക് പ്രവേശിക്കുക, MOM - അവസാന എയ്‌ഐ മാർക്കറ്റിലെ നിങ്ങളുടെ അനുഭവം ക്രമീകരിക്കുക. ഒരു താഴെ നിന്നും നിങ്ങളുടെ ഓർഡർകൾ, പ്രൊഫൈൽ മറ്റും സെറ്റിങ്കൾ അടങ്ങുക.' },
  ]
}));

// Turkish
allPages.push(mappedMetaData({
  title: 'Kullanıcı Hesabı - MOM - Son AI Marketplace',
  description: 'Kullanıcı hesabınıza erişin ve deneyiminizi kişiselleştirin MOM - Son AI Marketplace. Siparişlerinizi, profilinizi ve ayarlarınızı tek bir yerde yönetin.',
  canonical: `${appUrl}/tr/user`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/tr/user` },
    { property: 'og:title', content: 'Kullanıcı Hesabı - MOM - Son AI Marketplace' },
    { property: 'og:description', content: 'Kullanıcı hesabınıza erişin ve deneyiminizi kişiselleştirin MOM - Son AI Marketplace. Siparişlerinizi, profilinizi ve ayarlarınızı tek bir yerde yönetin.' },
  ]
}));

allPages.push(mappedMetaData({
  title: 'Boost Your Income Through the MOM Affiliate Program - MOM',
  description: 'Learn how to increase your earnings by joining the MOM Affiliate Program with this detailed guide.',
  canonical: `${appUrl}/blog/boost-your-income-through-the-mom-affiliate-program/54`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/blog/boost-your-income-through-the-mom-affiliate-program/54` },
    { property: 'og:title', content: 'Boost Your Income Through the MOM Affiliate Program - MOM' },
    { property: 'og:description', content: 'Learn how to increase your earnings by joining the MOM Affiliate Program with this detailed guide.' },
  ]
}));

allPages.push(mappedMetaData({
  title: 'MOM User Dashboard - MOM',
  description: 'Access and manage your MOM Dashboard through this user-friendly interface.',
  canonical: `${appUrl}/user?ref=producthunt`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/user?ref=producthunt` },
    { property: 'og:title', content: 'MOM User Dashboard - MOM' },
    { property: 'og:description', content: 'Access and manage your MOM Dashboard through this user-friendly interface.' },
  ]
}));

allPages.push(mappedMetaData({
  title: 'How to Start an Online Clothing Store: Your Comprehensive Guide - MOM',
  description: 'Discover all the steps and tips needed to start your own online clothing store with this comprehensive guide.',
  canonical: `${appUrl}/blog/how-to-start-an-online-clothing-store-your-comprehensive-guide/28`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/blog/how-to-start-an-online-clothing-store-your-comprehensive-guide/28` },
    { property: 'og:title', content: 'How to Start an Online Clothing Store: Your Comprehensive Guide - MOM' },
    { property: 'og:description', content: 'Discover all the steps and tips needed to start your own online clothing store with this comprehensive guide.' },
  ]
}));

allPages.push(mappedMetaData({
  title: 'How to Create a Digital Store in Less Than a Minute - MOM',
  description: 'Learn the fastest way to set up a digital store using MOM’s AI-powered tools.',
  canonical: `${appUrl}/blog/how-to-create-a-digital-store-in-less-than-a-minute/7`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/blog/how-to-create-a-digital-store-in-less-than-a-minute/7` },
    { property: 'og:title', content: 'How to Create a Digital Store in Less Than a Minute - MOM' },
    { property: 'og:description', content: 'Learn the fastest way to set up a digital store using MOM’s AI-powered tools.' },
  ]
}));

allPages.push(mappedMetaData({
  title: 'MOM AI Knowledge Base - MOM',
  description: 'Explore comprehensive articles and guides on how to utilize MOM AI.',
  canonical: `${appUrl}/knowledge-base/category/mom-ai/1`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/knowledge-base/category/mom-ai/1` },
    { property: 'og:title', content: 'MOM AI Knowledge Base - MOM' },
    { property: 'og:description', content: 'Explore comprehensive articles and guides on how to utilize MOM AI.' },
  ]
}));

allPages.push(mappedMetaData({
  title: 'How to Create an Order in POS - MOM',
  description: 'Step-by-step guide on creating an order in MOM POS system.',
  canonical: `${appUrl}/knowledge-base/details/how-to-create-an-order-in-pos/2/2`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/knowledge-base/details/how-to-create-an-order-in-pos/2/2` },
    { property: 'og:title', content: 'How to Create an Order in POS - MOM' },
    { property: 'og:description', content: 'Step-by-step guide on creating an order in MOM POS system.' },
  ]
}));

allPages.push(mappedMetaData({
  title: 'MOM Shop Knowledge Base - MOM',
  description: 'Find all the guidance and articles related to MOM Shop in this category.',
  canonical: `${appUrl}/knowledge-base/category/mom-shop/2`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/knowledge-base/category/mom-shop/2` },
    { property: 'og:title', content: 'MOM Shop Knowledge Base - MOM' },
    { property: 'og:description', content: 'Find all the guidance and articles related to MOM Shop in this category.' },
  ]
}));

allPages.push(mappedMetaData({
  title: 'MOM Main Dashboard - MOM',
  description: 'Access the main dashboard of MOM for a seamless experience.',
  canonical: `${appUrl}/en/main?rel=aiai.tools`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/en/main?rel=aiai.tools` },
    { property: 'og:title', content: 'MOM Main Dashboard - MOM' },
    { property: 'og:description', content: 'Access the main dashboard of MOM for a seamless experience.' },
  ]
}));

allPages.push(mappedMetaData({
  title: 'MOM Provider Knowledge Base - MOM',
  description: 'Explore articles and guides designed specifically for MOM Providers.',
  canonical: `${appUrl}/knowledge-base/category/mom-provider/3`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/knowledge-base/category/mom-provider/3` },
    { property: 'og:title', content: 'MOM Provider Knowledge Base - MOM' },
    { property: 'og:description', content: 'Explore articles and guides designed specifically for MOM Providers.' },
  ]
}));

allPages.push(mappedMetaData({
  title: 'How to Create Your Own Podcast: A Step-by-Step Guide - MOM',
  description: 'Comprehensive guide to creating, launching, and marketing your own podcast.',
  canonical: `${appUrl}/blog/how-to-create-your-own-podcast-a-step-by-step-guide/55`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/blog/how-to-create-your-own-podcast-a-step-by-step-guide/55` },
    { property: 'og:title', content: 'How to Create Your Own Podcast: A Step-by-Step Guide - MOM' },
    { property: 'og:description', content: 'Comprehensive guide to creating, launching, and marketing your own podcast.' },
  ]
}));

allPages.push(mappedMetaData({
  title: 'How to Use MOM AI Chat - MOM',
  description: 'Detailed instructions on how to effectively use MOM AI Chat for better communication.',
  canonical: `${appUrl}/knowledge-base/details/how-to-use-mom-ai-chat/1/5`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/knowledge-base/details/how-to-use-mom-ai-chat/1/5` },
    { property: 'og:title', content: 'How to Use MOM AI Chat - MOM' },
    { property: 'og:description', content: 'Detailed instructions on how to effectively use MOM AI Chat for better communication.' },
  ]
}));

// Spanish (es)
allPages.push(mappedMetaData({
  title: 'Programa de Afiliados de MOM - Gana Más Dinero',
  description: 'Aprende cómo aumentar tus ingresos uniéndote al Programa de Afiliados de MOM con esta guía detallada.',
  canonical: `${appUrl}/es/affilate`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/es/affilate` },
    { property: 'og:title', content: 'Programa de Afiliados de MOM - Gana Más Dinero' },
    { property: 'og:description', content: 'Aprende cómo aumentar tus ingresos uniéndote al Programa de Afiliados de MOM con esta guía detallada.' },
  ]
}));

// Russian (ru)
allPages.push(mappedMetaData({
  title: 'Аффилированная программа MOM - Увеличьте свои доходы',
  description: 'Узнайте, как увеличить свои доходы, присоединившись к аффилированной программе MOM, с помощью этого подробного руководства.',
  canonical: `${appUrl}/ru/affilate`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/ru/affilate` },
    { property: 'og:title', content: 'Аффилированная программа MOM - Увеличьте свои доходы' },
    { property: 'og:description', content: 'Узнайте, как увеличить свои доходы, присоединившись к аффилированной программе MOM, с помощью этого подробного руководства.' },
  ]
}));

// English (Default, en)
allPages.push(mappedMetaData({
  title: 'MOM Affiliate Program - Boost Your Income',
  description: 'Learn how to increase your earnings by joining the MOM Affiliate Program with this detailed guide.',
  canonical: `${appUrl}/en/affilate`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/en/affilate` },
    { property: 'og:title', content: 'MOM Affiliate Program - Boost Your Income' },
    { property: 'og:description', content: 'Learn how to increase your earnings by joining the MOM Affiliate Program with this detailed guide.' },
  ]
}));

// Urdu (ur)
allPages.push(mappedMetaData({
  title: 'MOM ایفیلیٹ پروگرام - اپنی آمدنی بڑھائیں',
  description: 'MOM ایفیلیٹ پروگرام میں شامل ہوکر اپنی آمدنی کو بڑھانے کے لئے اس تفصیلی گائیڈ کا مطالعہ کریں۔',
  canonical: `${appUrl}/ur/affilate`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/ur/affilate` },
    { property: 'og:title', content: 'MOM ایفیلیٹ پروگرام - اپنی آمدنی بڑھائیں' },
    { property: 'og:description', content: 'MOM ایفیلیٹ پروگرام میں شامل ہوکر اپنی آمدنی کو بڑھانے کے لئے اس تفصیلی گائیڈ کا مطالعہ کریں۔' },
  ]
}));

// Malayalam (ml)
allPages.push(mappedMetaData({
  title: 'MOM അഫിലിയേറ്റ് പ്രോഗ്രാം - നിങ്ങളുടെ വരുമാനം വർദ്ധിപ്പിക്കുക',
  description: 'MOM അഫിലിയേറ്റ് പ്രോഗ്രാമിൽ ചേരുന്നതിലൂടെ നിങ്ങളുടെ വരുമാനം എങ്ങനെ വർദ്ധിപ്പിക്കാമെന്ന് ഈ വിശദമായ മാർഗ്ഗനിർദ്ദേശത്തിൽ പഠിക്കുക.',
  canonical: `${appUrl}/ml/affilate`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/ml/affilate` },
    { property: 'og:title', content: 'MOM അഫിലിയേറ്റ് പ്രോഗ്രാം - നിങ്ങളുടെ വരുമാനം വർദ്ധിപ്പിക്കുക' },
    { property: 'og:description', content: 'MOM അഫിലിയേറ്റ് പ്രോഗ്രാമിൽ ചേരുന്നതിലൂടെ നിങ്ങളുടെ വരുമാനം എങ്ങനെ വർദ്ധിപ്പിക്കാമെന്ന് ഈ വിശദമായ മാർഗ്ഗനിർദ്ദേശത്തിൽ പഠിക്കുക.' },
  ]
}));

// Hindi (hi)
allPages.push(mappedMetaData({
  title: 'MOM एफिलिएट प्रोग्राम - अपनी आय बढ़ाएं',
  description: 'इस विस्तृत गाइड के साथ MOM एफिलिएट प्रोग्राम में शामिल होकर अपनी आय कैसे बढ़ाएं, जानें।',
  canonical: `${appUrl}/hi/affilate`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/hi/affilate` },
    { property: 'og:title', content: 'MOM एफिलिएट प्रोग्राम - अपनी आय बढ़ाएं' },
    { property: 'og:description', content: 'इस विस्तृत गाइड के साथ MOM एफिलिएट प्रोग्राम में शामिल होकर अपनी आय कैसे बढ़ाएं, जानें।' },
  ]
}));

// German (de)
allPages.push(mappedMetaData({
  title: 'MOM Affiliate-Programm - Erhöhen Sie Ihr Einkommen',
  description: 'Erfahren Sie, wie Sie Ihr Einkommen durch die Teilnahme am MOM Affiliate-Programm steigern können, mit diesem ausführlichen Leitfaden.',
  canonical: `${appUrl}/de/affilate`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/de/affilate` },
    { property: 'og:title', content: 'MOM Affiliate-Programm - Erhöhen Sie Ihr Einkommen' },
    { property: 'og:description', content: 'Erfahren Sie, wie Sie Ihr Einkommen durch die Teilnahme am MOM Affiliate-Programm steigern können, mit diesem ausführlichen Leitfaden.' },
  ]
}));

// Chinese (zh)
allPages.push(mappedMetaData({
  title: 'MOM 联盟计划 - 提高您的收入',
  description: '通过加入 MOM 联盟计划学习如何提高您的收入，详细指南请见此处。',
  canonical: `${appUrl}/zh/affilate`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/zh/affilate` },
    { property: 'og:title', content: 'MOM 联盟计划 - 提高您的收入' },
    { property: 'og:description', content: '通过加入 MOM 联盟计划学习如何提高您的收入，详细指南请见此处。' },
  ]
}));

// Arabic (ar)
allPages.push(mappedMetaData({
  title: 'برنامج MOM للشراكة - زِد من دخلك',
  description: 'تعرف على كيفية زيادة دخلك من خلال الانضمام إلى برنامج الشراكة MOM عبر هذا الدليل المفصل.',
  canonical: `${appUrl}/ar/affilate`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/ar/affilate` },
    { property: 'og:title', content: 'برنامج MOM للشراكة - زِد من دخلك' },
    { property: 'og:description', content: 'تعرف على كيفية زيادة دخلك من خلال الانضمام إلى برنامج الشراكة MOM عبر هذا الدليل المفصل.' },
  ]
}));

// Tamil (ta)
allPages.push(mappedMetaData({
  title: 'MOM இணைப்பு திட்டம் - உங்கள் வருமானத்தை அதிகரிக்கவும்',
  description: 'MOM இணைப்பு திட்டத்தில் சேர்ந்து உங்கள் வருமானத்தை அதிகரிப்பது எப்படி என்று இந்த விரிவான வழிகாட்டி மூலம் அறிக.',
  canonical: `${appUrl}/ta/affilate`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/ta/affilate` },
    { property: 'og:title', content: 'MOM இணைப்பு திட்டம் - உங்கள் வருமானத்தை அதிகரிக்கவும்' },
    { property: 'og:description', content: 'MOM இணைப்பு திட்டத்தில் சேர்ந்து உங்கள் வருமானத்தை அதிகரிப்பது எப்படி என்று இந்த விரிவான வழிகாட்டி மூலம் அறிக.' },
  ]
}));

// Thai (th)
allPages.push(mappedMetaData({
  title: 'โปรแกรมพันธมิตร MOM - เพิ่มรายได้ของคุณ',
  description: 'เรียนรู้วิธีเพิ่มรายได้ของคุณโดยเข้าร่วมโปรแกรมพันธมิตร MOM ด้วยคำแนะนำอย่างละเอียดนี้.',
  canonical: `${appUrl}/th/affilate`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/th/affilate` },
    { property: 'og:title', content: 'โปรแกรมพันธมิตร MOM - เพิ่มรายได้ของคุณ' },
    { property: 'og:description', content: 'เรียนรู้วิธีเพิ่มรายได้ของคุณโดยเข้าร่วมโปรแกรมพันธมิตร MOM ด้วยคำแนะนำอย่างละเอียดนี้.' },
  ]
}));

// Portuguese (pt)
allPages.push(mappedMetaData({
  title: 'Programa de Afiliados MOM - Aumente Seus Ganhos',
  description: 'Aprenda como aumentar seus ganhos juntando-se ao Programa de Afiliados MOM com este guia detalhado.',
  canonical: `${appUrl}/pt/affilate`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/pt/affilate` },
    { property: 'og:title', content: 'Programa de Afiliados MOM - Aumente Seus Ganhos' },
    { property: 'og:description', content: 'Aprenda como aumentar seus ganhos juntando-se ao Programa de Afiliados MOM com este guia detalhado.' },
  ]
}));

// Italian (it)
allPages.push(mappedMetaData({
  title: 'Programma di Affiliazione MOM - Incrementa i Tuoi Guadagni',
  description: 'Scopri come aumentare i tuoi guadagni aderendo al Programma di Affiliazione MOM con questa guida dettagliata.',
  canonical: `${appUrl}/it/affilate`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/it/affilate` },
    { property: 'og:title', content: 'Programma di Affiliazione MOM - Incrementa i Tuoi Guadagni' },
    { property: 'og:description', content: 'Scopri come aumentare i tuoi guadagni aderendo al Programma di Affiliazione MOM con questa guida dettagliata.' },
  ]
}));

// Bengali (bn)
allPages.push(mappedMetaData({
  title: 'MOM অ্যাফিলিয়েট প্রোগ্রাম - আপনার আয় বৃদ্ধি করুন',
  description: 'এই বিস্তারিত গাইডের মাধ্যমে MOM অ্যাফিলিয়েট প্রোগ্রামে যোগ দিয়ে আপনার আয় বৃদ্ধি করার উপায় জানুন।',
  canonical: `${appUrl}/bn/affilate`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/bn/affilate` },
    { property: 'og:title', content: 'MOM অ্যাফিলিয়েট প্রোগ্রাম - আপনার আয় বৃদ্ধি করুন' },
    { property: 'og:description', content: 'এই বিস্তারিত গাইডের মাধ্যমে MOM অ্যাফিলিয়েট প্রোগ্রামে যোগ দিয়ে আপনার আয় বৃদ্ধি করার উপায় জানুন।' },
  ]
}));

// French (fr)
allPages.push(mappedMetaData({
  title: 'Programme d’Affiliation MOM - Augmentez Vos Revenus',
  description: 'Apprenez comment augmenter vos revenus en rejoignant le Programme d’Affiliation MOM avec ce guide détaillé.',
  canonical: `${appUrl}/fr/affilate`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/fr/affilate` },
    { property: 'og:title', content: 'Programme d’Affiliation MOM - Augmentez Vos Revenus' },
    { property: 'og:description', content: 'Apprenez comment augmenter vos revenus en rejoignant le Programme d’Affiliation MOM avec ce guide détaillé.' },
  ]
}));

// Indonesian (id)
allPages.push(mappedMetaData({
  title: 'Program Afiliasi MOM - Tingkatkan Penghasilan Anda',
  description: 'Pelajari cara meningkatkan penghasilan Anda dengan bergabung ke Program Afiliasi MOM melalui panduan terperinci ini.',
  canonical: `${appUrl}/id/affilate`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/id/affilate` },
    { property: 'og:title', content: 'Program Afiliasi MOM - Tingkatkan Penghasilan Anda' },
    { property: 'og:description', content: 'Pelajari cara meningkatkan penghasilan Anda dengan bergabung ke Program Afiliasi MOM melalui panduan terperinci ini.' },
  ]
}));

// Turkish (tr)
allPages.push(mappedMetaData({
  title: 'MOM Ortaklık Programı - Gelirinizi Artırın',
  description: 'Bu detaylı kılavuzla MOM Ortaklık Programına katılarak gelirinizi nasıl artıracağınızı öğrenin.',
  canonical: `${appUrl}/tr/affilate`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/tr/affilate` },
    { property: 'og:title', content: 'MOM Ortaklık Programı - Gelirinizi Artırın' },
    { property: 'og:description', content: 'Bu detaylı kılavuzla MOM Ortaklık Programına katılarak gelirinizi nasıl artıracağınızı öğrenin.' },
  ]
}));

// Uzbek (uz)
allPages.push(mappedMetaData({
  title: 'MOM Affiliate Dasturi - Daromadingizni Oshiring',
  description: 'MOM Affiliate dasturiga qo\'shilib, daromadingizni oshirish uchun bu batafsil qo\'llanmani o\'rganing.',
  canonical: `${appUrl}/uz/affilate`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/uz/affilate` },
    { property: 'og:title', content: 'MOM Affiliate Dasturi - Daromadingizni Oshiring' },
    { property: 'og:description', content: 'MOM Affiliate dasturiga qo\'shilib, daromadingizni oshirish uchun bu batafsil qo\'llanmani o\'rganing.' },
  ]
}));

// Default Language (English) - Blog: Create a Digital Store
allPages.push(mappedMetaData({
  title: 'How to Create a Digital Store in Less than a Minute - MOM Blog',
  description: 'A quick guide to setting up your digital store with MOM in less than a minute.',
  canonical: `${appUrl}/blog/how-to-create-a-digital-store-in-less-than-an-minute/7`,
  og: [
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: `${appUrl}/blog/how-to-create-a-digital-store-in-less-than-an-minute/7` },
    { property: 'og:title', content: 'How to Create a Digital Store in Less than a Minute - MOM Blog' },
    { property: 'og:description', content: 'A quick guide to setting up your digital store with MOM in less than a minute.' },
  ]
}));

function mappedMetaData(data) {
  let meta = {};

  if (data.title) {
    meta.title = data.title;
  }

  if (data.description) {
    meta.meta = [{ name: 'description', content: data.description }];
  }

  if (data.og) {
    meta.meta = meta.meta || [];
    meta.meta.push(...data.og);

    const hasOgImage = data.og.some(i => i.property === 'og:image');
    if (!hasOgImage) {
      meta.meta.push(...commonOgImage);
    }
  }

  if (data.canonical) {
    meta.link = [{ rel: 'canonical', href: data.canonical }];
  }

  return meta;
}

const currentPage = allPages.find(i => i.link.some(l => l.rel === 'canonical' && l.href === curUrl));

if (currentPage) {
  const { title, meta, link } = currentPage;

  // Update the page title
  if (title) {
    document.title = title;
    const titleEle = document.querySelector('title');
    if (titleEle) {
      titleEle.innerText = title;
    }
  }

  // Update the canonical link
  if (link && Array.isArray(link) && link.length > 0) {
    const canonicalObj = link.find(l => l.rel === 'canonical');
    if (canonicalObj && canonicalObj.href) {
      let canonicalLink = document.querySelector('link[rel="canonical"]');
      if (!canonicalLink) {
        canonicalLink = document.createElement('link');
        canonicalLink.rel = 'canonical';
        document.head.appendChild(canonicalLink);
      }
      canonicalLink.href = canonicalObj.href;
    }
  }

  // Update meta tags
  if (meta && Array.isArray(meta) && meta.length > 0) {
    meta.forEach(m => {
      const metaSelector = m.name ? `meta[name="${m.name}"]` : `meta[property="${m.property}"]`;
      let metaEle = document.querySelector(metaSelector);

      if (!metaEle) {
        metaEle = document.createElement('meta');
        if (m.name) {
          metaEle.setAttribute('name', m.name);
        } else if (m.property) {
          metaEle.setAttribute('property', m.property);
        }
      }
      if (m.content) {
        metaEle.setAttribute("content", m.content);
      }
      document.head.appendChild(metaEle);
    });
  }
}

export default allPages;