export default [
  {
    path: '/shop/login',
    name: 'apps-shop-login',
    component: () => import('@/views/auth/Login.vue'),
    meta: {
      layout: 'full',
      sharedType: 'shop',
      type: 'shop',
    },
  },
  {
    path: '/shop/registration',
    name: 'apps-shop-registration',
    component: () => import('@/views/auth/Registration.vue'),
    meta: {
      layout: 'full',
      sharedType: 'shop',
      type: 'shop',
    },
  },
  {
    path: '/shop/forget-password',
    name: 'apps-shop-forget-password',
    component: () => import('@/views/auth/ForgetPassword.vue'),
    meta: {
      layout: 'full',
      sharedType: 'shop',
    },
  },
  {
    path: '/shop/change-password',
    name: 'shop-change-password',
    component: () => import('@/views/store/ChangePassword.vue'),
    meta: {
      pageTitle: 'Change Password',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/dashboard',
    name: 'shop-dashboard',
    component: () => import('@/views/store/Dashboard.vue'),
    meta: {
      pageTitle: 'Dashboard',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/analytics',
    name: 'shop-analytics',
    component: () => import('@/views/store/Analytics.vue'),
    meta: {
      pageTitle: 'Analytics',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  // {
  //   path: '/shop/dispatcher',
  //   name: 'shop-dispatcher',
  //   component: () => import('@/views/store/Dispatcher.vue'),
  //   meta: {
  //     pageTitle: 'Dispatcher',
  //     showBreadCrumb: false,
  //     canActivate: true,
  //     contentClass: 'ecommerce-application',
  //     type: 'shop',
  //   },
  // },
  {
    path: '/shop/my-documents',
    name: 'shop-documents',
    component: () => import('@/views/store/MyDocuments.vue'),
    meta: {
      pageTitle: 'My Documents',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/bank-details',
    name: 'shop-bank-details',
    component: () => import('@/views/store/BankDetails.vue'),
    meta: {
      pageTitle: 'Bank Details',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/profile',
    name: 'shop-profile',
    component: () => import('@/views/store/Profile.vue'),
    meta: {
      pageTitle: 'Profile',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/promocodes',
    name: 'shop-promocodes',
    component: () => import('@/views/store/Promocodes.vue'),
    meta: {
      pageTitle: 'Promocodes',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/category',
    name: 'shop-category',
    component: () => import('@/views/store/Category.vue'),
    meta: {
      pageTitle: 'Category',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/brand',
    name: 'shop-brand',
    component: () => import('@/views/store/Brand.vue'),
    meta: {
      pageTitle: 'Brand',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/bracode-sheet',
    name: 'shop-bracode-sheet',
    component: () => import('@/views/store/GenerateBarcodeSheet.vue'),
    meta: {
      pageTitle: 'Generate Barcode Sheet',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/variations',
    name: 'shop-variations',
    component: () => import('@/views/store/Variations.vue'),
    meta: {
      pageTitle: 'Variations',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/agents',
    name: 'shop-agents',
    component: () => import('@/views/store/Agents.vue'),
    meta: {
      pageTitle: 'Agents',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/agents-dashboard',
    name: 'shop-agents-dashboard',
    component: () => import('@/views/store/AgentDashboard.vue'),
    meta: {
      pageTitle: 'Agent Dashboard',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/agents-pricing',
    name: 'shop-agents-pricing',
    component: () => import('@/views/store/AgentPricing.vue'),
    meta: {
      pageTitle: 'Agent Pricing',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/agents-earning',
    name: 'shop-agents-earning',
    component: () => import('@/views/store/AgentEarnings.vue'),
    meta: {
      pageTitle: 'Agent Earning',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/agents-tracking',
    name: 'shop-agents-tracking',
    component: () => import('@/views/store/AgentTracking.vue'),
    meta: {
      pageTitle: 'Agent Tracking',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/agents-attendance',
    name: 'shop-agents-attendance',
    component: () => import('@/views/store/AgentAttendance.vue'),
    meta: {
      pageTitle: 'Agent Attendance',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/delivery-settings',
    name: 'shop-delivery-settings',
    component: () => import('@/views/store/DeliverySettings.vue'),
    meta: {
      pageTitle: 'Delivery Settings',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/notifications',
    name: 'shop-notifications',
    component: () => import('@/views/store/Notifications.vue'),
    meta: {
      pageTitle: 'Shop Notifications',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/reviews',
    name: 'shop-reviews',
    component: () => import('@/views/store/Reviews.vue'),
    meta: {
      pageTitle: 'Shop Reviews',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/messaging',
    name: 'shop-messaging',
    component: () => import('@/views/store/Messages.vue'),
    meta: {
      pageTitle: 'Shop Messages',
      showBreadCrumb: false,
      canActivate: true,
      contentRenderer: 'sidebar-left',
      contentClass: 'chat-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/booking',
    name: 'shop-bookings',
    component: () => import('@/views/store/Bookings.vue'),
    meta: {
      pageTitle: 'Shop Booking',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/addons',
    name: 'shop-addons',
    component: () => import('@/views/store/AddOns.vue'),
    meta: {
      pageTitle: 'Shop Add Ons',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/order/find-agent/:orderId',
    name: 'shop-find-agent',
    component: () => import('@/views/store/FindAgents.vue'),
    meta: {
      pageTitle: 'Order Find Agent',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/user-reviews',
    name: 'shop-user-reviews',
    component: () => import('@/views/store/StoreReviews.vue'),
    meta: {
      pageTitle: 'Store Reviews',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/overviews',
    name: 'shop-overviews',
    component: () => import('@/views/store/Overviews.vue'),
    meta: {
      pageTitle: 'Store Overviews',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/photos',
    name: 'shop-photos',
    component: () => import('@/views/store/Photos.vue'),
    meta: {
      pageTitle: 'Store Photos',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/order/agent/view/:orderId',
    name: 'shop-agent-view',
    component: () => import('@/views/users/StoreOrder.vue'),
    meta: {
      pageTitle: 'Order Agent View',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/items',
    name: 'shop-items',
    component: () => import('@/views/store/Items.vue'),
    meta: {
      pageTitle: 'Items',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/warehouses',
    name: 'shop-warehouse',
    component: () => import('@/views/store/WareHouses.vue'),
    meta: {
      pageTitle: 'Warehouse',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/wallet',
    name: 'shop-wallet',
    component: () => import('@/views/store/Wallets.vue'),
    meta: {
      pageTitle: 'Wallets',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/order-history',
    name: 'order-history',
    component: () => import('@/views/store/OrderHistory.vue'),
    meta: {
      pageTitle: 'Order History',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/order-statement',
    name: 'order-statement',
    component: () => import('@/views/store/OrderStatement.vue'),
    meta: {
      pageTitle: 'Order Statement',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/shop-statement',
    name: 'shop-statement',
    component: () => import('@/views/store/ShopStatement.vue'),
    meta: {
      pageTitle: 'Shop Statement',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/hrm-departments',
    name: 'shop-hrm-departments',
    component: () => import('@/views/shared/hrm/Department.vue'),
    meta: {
      pageTitle: 'HRM Departments',
      showBreadCrumb: true,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/hrm-designation',
    name: 'shop-hrm-designation',
    component: () => import('@/views/shared/hrm/Designation.vue'),
    meta: {
      pageTitle: 'HRM Designation',
      showBreadCrumb: true,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/hrm-attendance',
    name: 'shop-hrm-attendance',
    component: () => import('@/views/shared/hrm/Attendance.vue'),
    meta: {
      pageTitle: 'HRM Attendance',
      showBreadCrumb: true,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/hrm-holidays',
    name: 'shop-hrm-holidays',
    component: () => import('@/views/shared/hrm/Holidays.vue'),
    meta: {
      pageTitle: 'HRM Holidays',
      showBreadCrumb: true,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/hrm-shift',
    name: 'shop-hrm-shift',
    component: () => import('@/views/shared/hrm/OfficeShift.vue'),
    meta: {
      pageTitle: 'HRM Shift',
      showBreadCrumb: true,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/hrm-payrolls',
    name: 'shop-hrm-payrolls',
    component: () => import('@/views/shared/hrm/Payrolls.vue'),
    meta: {
      pageTitle: 'HRM Payrolls',
      showBreadCrumb: true,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/hrm-employees',
    name: 'shop-hrm-employees',
    component: () => import('@/views/shared/hrm/employees'),
    redirect: "/shop/hrm-employees/list",
    meta: {
      pageTitle: 'HRM Employees',
      showBreadCrumb: true,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
    children: [
      {
        name: "shop-employees_list",
        path: "list",
        component: () => import('@/views/shared/hrm/employees/index_employee'),
        meta: {
          pageTitle: 'HRM Employees',
          showBreadCrumb: false,
          canActivate: true,
          contentClass: 'ecommerce-application',
          type: 'shop',
        },
      },
      {
        name: "shop-store_employee",
        path: "store",
        component: () => import('@/views/shared/hrm/employees/employee_create'),
        meta: {
          pageTitle: 'HRM Employees',
          showBreadCrumb: false,
          canActivate: true,
          contentClass: 'ecommerce-application',
          type: 'shop',
        },
      },
      {
        name: "shop-edit_employee",
        path: "edit/:id",
        component: () => import('@/views/shared/hrm/employees/employee_edit'),
        meta: {
          pageTitle: 'HRM Employees',
          showBreadCrumb: false,
          canActivate: true,
          contentClass: 'ecommerce-application',
          type: 'shop',
        },
      },
      {
        name: "shop-detail_employee",
        path: "detail/:id",
        component: () => import('@/views/shared/hrm/employees/employee_details'),
        meta: {
          pageTitle: 'HRM Employees',
          showBreadCrumb: false,
          canActivate: true,
          contentClass: 'ecommerce-application',
          type: 'shop',
        },
      },
    ]
  },
  {
    path: '/shop/hrm-leaves',
    name: 'shop-hrm-leaves',
    component: () => import('@/views/shared/hrm/leaves'),
    redirect: "/shop/hrm-leaves/list",
    meta: {
      pageTitle: 'HRM Leaves',
      showBreadCrumb: true,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
    children: [
      {
        name: "shop-leave_list",
        path: "list",
        component: () => import('@/views/shared/hrm/leaves/leave_list'),
        meta: {
          pageTitle: 'Leaves',
          showBreadCrumb: false,
          canActivate: true,
          contentClass: 'ecommerce-application',
          type: 'shop',
        },
      },
    ]
  },
  {
    path: '/shop/hrm-leave-types',
    name: 'shop-hrm-leave-types',
    component: () => import('@/views/shared/hrm/leaves'),
    redirect: "/shop/hrm-leave-types/type",
    meta: {
      pageTitle: 'HRM Leave Types',
      showBreadCrumb: true,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
    children: [
      {
        name: "shop-leave_type",
        path: "type",
        component: () => import('@/views/shared/hrm/leaves/leave_type'),
        meta: {
          pageTitle: 'Leave Types',
          showBreadCrumb: false,
          canActivate: true,
          contentClass: 'ecommerce-application',
          type: 'shop',
        },
      },
    ]
  },
  {
    path: '/shop/account/accounts',
    name: 'shop-account-accounts',
    component: () => import('@/views/shared/account/Accounts.vue'),
    meta: {
      pageTitle: 'Accounts',
      showBreadCrumb: true,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/account/expense',
    name: 'shop-account-expenses',
    component: () => import('@/views/shared/account/expense/index_expense'),
    meta: {
      pageTitle: 'Accounts',
      showBreadCrumb: true,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/account/expenses',
    name: 'shop-account-expenses',
    component: () => import('@/views/shared/account/expense/index_expense'),
    meta: {
      pageTitle: 'Expenses',
      showBreadCrumb: true,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/account/expenses/categories',
    name: 'shop-account-expense-categories',
    component: () => import('@/views/shared/account/expense/category_expense'),
    meta: {
      pageTitle: 'Expense Categories',
      showBreadCrumb: true,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/account/deposits',
    name: 'shop-account-deposits',
    component: () => import('@/views/shared/account/deposits/index_deposit'),
    meta: {
      pageTitle: 'Deposits',
      showBreadCrumb: true,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/account/deposits/categories',
    name: 'shop-account-deposits-categories',
    component: () => import('@/views/shared/account/deposits/deposit_category'),
    meta: {
      pageTitle: 'Deposit Categories',
      showBreadCrumb: true,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/account/transfer-money',
    name: 'shop-account-transfer-money',
    component: () => import('@/views/shared/account/TransferMoney'),
    meta: {
      pageTitle: 'Transfer Money',
      showBreadCrumb: true,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/payment-config',
    name: 'payment-config',
    component: () => import('@/views/store/PaymentConfig.vue'),
    meta: {
      pageTitle: 'Payment Config',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/policies',
    name: 'shop-policies',
    component: () => import('@/views/store/Policies.vue'),
    meta: {
      pageTitle: 'Shop Policies',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },

  {
    path: '/shop/affiliation-overview',
    name: 'shop-affiliation-overview',
    component: () => import('@/views/shared/affilation/AffilationOverview.vue'),
    meta: {
      pageTitle: 'Affilate Overview',
      showBreadCrumb: true,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/affiliation-payments',
    name: 'shop-affiliation-payments',
    component: () => import('@/views/shared/affilation/payments/index.vue'),
    meta: {
      pageTitle: 'Affilate Payments',
      showBreadCrumb: true,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/affiliation-payouts',
    name: 'shop-affiliation-payouts',
    component: () => import('@/views/shared/affilation/payouts/index.vue'),
    meta: {
      pageTitle: 'Affilate Payouts',
      showBreadCrumb: true,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/affiliation-earnings',
    name: 'shop-affiliation-earnings',
    component: () => import('@/views/shared/affilation/earnings/index.vue'),
    meta: {
      pageTitle: 'Affiliation Earnings',
      showBreadCrumb: true,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/ai/images',
    name: 'shop-ai-images',
    component: () => import('@/views/users/ai/AIImages.vue'),
    meta: {
      pageTitle: 'Shop AI Images',
      showBreadCrumb: true,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/ai/images/saved',
    name: 'shop-ai-images-saved',
    component: () => import('@/views/users/ai/AISavedImages.vue'),
    meta: {
      pageTitle: 'Shop AI Saved Images',
      showBreadCrumb: true,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/ai/writer',
    name: 'shop-ai-writer',
    component: () => import('@/views/users/ai/AiTemplates.vue'),
    meta: {
      pageTitle: 'Shop Templates',
      showBreadCrumb: true,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/ai/templates/:slug',
    name: 'shop-ai-generate-template',
    component: () => import('@/views/users/ai/GenerateAiTemplate.vue'),
    meta: {
      pageTitle: 'Shop Generate Template',
      showBreadCrumb: true,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/ai/templates/edit/:id',
    name: 'shop-edit-ai-generated-template',
    component: () => import('@/views/users/ai/GeneratedAiTemplate.vue'),
    meta: {
      pageTitle: 'Shop Edit Generate Template',
      showBreadCrumb: true,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
    },
  },
  {
    path: '/shop/support',
    name: 'shop-support',
    component: () => import('@/views/store/StoreSupport.vue'),
    meta: {
      pageTitle: 'Shop Support',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
      userType: 'shop',
    },
  },
  {
    path: '/shop/sla-settings',
    name: 'shop-sla-settings',
    component: () => import('@/views/sla/SlaSettings.vue'),
    meta: {
      pageTitle: 'Shop SLA Settings',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
      userType: 'shop',
    },
  },
  {
    path: '/shop/notification-settings',
    name: 'shop-notification-settings',
    component: () => import('@/views/notification-template/NotificationTemplateSettings.vue'),
    meta: {
      pageTitle: 'Shop Notification Template Settings',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
      userType: 'shop',
    },
  },
  {
    path: '/shop/thirdparty-settings',
    name: 'shop-thirdparty-settings',
    component: () => import('@/views/thirdparty/ThirpartyConfig.vue'),
    meta: {
      pageTitle: 'Shop 3rd Party Settings',
      showBreadCrumb: false,
      canActivate: true,
      contentClass: 'ecommerce-application',
      type: 'shop',
      userType: 'shop',
    },
  },
]
